import { ZONE, CELL, CELL_LEADER, HOUSE_HOLDER, HOUSE_BILL, MONTH_BILL, BILLS_PAYMENTS, LGA } from '../api';
import { postRequest, postFiles, getRequest, queryParams } from './CoreService';

export class LgaService {

    addZone(dataJson) {
        return postRequest({
            url: ZONE,
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    editZone(dataJson) {
        return postRequest({
            url: ZONE,
            method: "PUT",
            body: JSON.stringify(dataJson)
        });
    }

    getZone(dataId) {
        return postRequest({
            url: ZONE + "/" + dataId,
            method: "GET"
        });
    }

    removeZone(dataId) {
        return postRequest({
            url: ZONE + "/" + dataId,
            method: "DELETE"
        });
    }

    getZones(jsonParams) {
        return getRequest({
            url: ZONE + queryParams(jsonParams),
            method: 'GET'
        });
    }

    addCell(dataJson) {
        return postRequest({
            url: CELL,
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    editCell(dataJson) {
        return postRequest({
            url: CELL,
            method: "PUT",
            body: JSON.stringify(dataJson)
        });
    }

    getCell(dataId) {
        return postRequest({
            url: CELL + "/" + dataId,
            method: "GET"
        });
    }

    removeCell(dataId) {
        return postRequest({
            url: CELL + "/" + dataId,
            method: "DELETE"
        });
    }

    getCells(jsonParams) {
        return getRequest({
            url: CELL + queryParams(jsonParams),
            method: 'GET'
        });
    }

    addCellLeader(dataJson) {
        return postRequest({
            url: CELL_LEADER,
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    editCellLeader(dataJson) {
        return postRequest({
            url: CELL_LEADER,
            method: "PUT",
            body: JSON.stringify(dataJson)
        });
    }

    getCellLeader(dataId) {
        return postRequest({
            url: CELL_LEADER + "/" + dataId,
            method: "GET"
        });
    }

    removeCellLeader(dataId) {
        return postRequest({
            url: CELL_LEADER + "/" + dataId,
            method: "DELETE"
        });
    }

    getCellLeaders(jsonParams) {
        return getRequest({
            url: CELL_LEADER + queryParams(jsonParams),
            method: 'GET'
        });
    }

    addHouseHolder(dataJson) {
        return postRequest({
            url: HOUSE_HOLDER,
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }
    addHouseHolderBatch(excelFile, cellId) {
        const formData = new FormData();
        formData.append('file', excelFile);
        formData.append('cellId', cellId);
        return postFiles({
            url: HOUSE_HOLDER + "/batch",
            method: 'POST',
            body: formData
        });
    }

    editHouseHolder(dataJson) {
        return postRequest({
            url: HOUSE_HOLDER,
            method: "PUT",
            body: JSON.stringify(dataJson)
        });
    }

    getHouseHolder(dataId) {
        return postRequest({
            url: HOUSE_HOLDER + "/" + dataId,
            method: "GET"
        });
    }

    getHouseHolderByReference(reference) {
        return postRequest({
            url: HOUSE_HOLDER + "/by/reference/" + reference,
            method: "GET"
        });
    }

    removeHouseHolder(dataId) {
        return postRequest({
            url: HOUSE_HOLDER + "/" + dataId,
            method: "DELETE"
        });
    }

    getHouseHolders(jsonParams) {
        return getRequest({
            url: HOUSE_HOLDER + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getHouseBill(feeId) {
        return getRequest({
            url: HOUSE_BILL + "/" + feeId,
            method: 'GET'
        });
    }

    getHouseBills(jsonParams) {
        return getRequest({
            url: HOUSE_BILL + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getHouseHoldersMonthBills(month) {
        return postRequest({
            url: HOUSE_HOLDER + "/bills/" + month,
            method: "GET"
        });
    }

    getHouseHolderMonthBills(month, houseHolderId) {
        return postRequest({
            url: HOUSE_HOLDER + "/bills/" + month + "/" + houseHolderId,
            method: "GET"
        });
    }

    splitHouseBill(dataJson) {
        return postRequest({
            url: MONTH_BILL + "/split",
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    revokeMonthPayment(dataJson) {
        return postRequest({
            url: MONTH_BILL + "/revoke",
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    getMonthBills(jsonParams) {
        return getRequest({
            url: MONTH_BILL + queryParams(jsonParams),
            method: 'GET'
        });
    }

    transferBillsPayments(dataJson) {
        return postRequest({
            url: LGA + "/transfer/bills/payments",
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    getTransferredBillsPayments(jsonParams) {
        return getRequest({
            url: LGA + "/transferred/bills/payments" + queryParams(jsonParams),
            method: 'GET'
        });
    }
}