import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { WalletService } from '../../service/WalletService';
import { LgaService } from '../../service/LgaService';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';
import { Dropdown } from 'primereact/dropdown';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";

class HouseBills extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue:[],
            cells: [],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            cellId: 0,
            name: '',
            paymentId: '',
            houseNumber: '',
            phoneNumber: '',
            tenantType: '',
            search: '',
            sortOrder: -1,
            sortField: 'id',
            loading: false
        };

        this.walletService = new WalletService();
        this.lgaService = new LgaService();
        this.adalipaUtil = new AdalipaUtil();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.removeData = this.removeData.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.amountTemplate = this.amountTemplate.bind(this);
        this.cellTemplate = this.cellTemplate.bind(this);
        this.paidAmountTemplate = this.paidAmountTemplate.bind(this);
        this.export = this.export.bind(this);

        this.cols = [
            { field: 'name', header: this.props.t('House Holder') },
            { field: 'paymentId', header: this.props.t('Reference') },
            { field: 'houseNumber', header: this.props.t('House Number') },
            { field: 'phoneNumber', header: this.props.t('Phone Number') },
            { field: 'cellNumber', header: this.props.t('Cell Number') },
            // { field: 'paidAmount', header: this.props.t('Paid Amount') }
        ];
        this.excelCols = {header: ['name', 'paymentId', 'houseNumber', 'phoneNumber', 'cellNumber', 'paidAmount']};
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }

    getDataList = () => {
        this.setState({loading: true});
        return this.lgaService.getHouseBills({cellId: this.state.cellId, name: this.state.name, paymentId: this.state.paymentId, houseNumber: this.state.houseNumber, phoneNumber: this.state.phoneNumber, tenantType: this.state.tenantType, search: this.state.search, page: this.state.pageIndex, limit: this.state.pageSize, sort: this.state.sortField, order: this.state.sortOrder === 1 ? 'asc' : 'desc'})
        .then(data => {
            this.setState({loading: false});
            const dataContent = data.content.map(d => ({
                ...d,
                holderId: d.houseHolder.id,
                name: d.houseHolder.name,
                houseNumber: d.houseHolder.houseNumber,
                phoneNumber: d.houseHolder.phoneNumber,
                cellNumber: d.houseHolder.cell.cellNumber + " - " + d.houseHolder.cell.zone.name,
                paidAmount: this.adalipaUtil.amountFormat(d.paidAmount)
            }))
            this.setState({dataTableValue: dataContent});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
    }

    componentDidMount() {
        this.getDataList();
        const jsonParams = { page: 0, limit: 100, sort: "id", order: 'asc' }
        this.lgaService.getCells(jsonParams)
        .then(data => {
            const dataContent = data.content;
            const options = dataContent.map(d => ({
                "id" : d.id,
                "name" : d.cellNumber + " - " + (d.zone.name)
            }))
            this.setState({cells: options});
        });
    }

    onPageChange(event) {
        this.setState({pageIndex: event.page, pageSize: event.rows}, () => {
            this.getDataList().then(res => {
                this.setState({first: event.first, pageSize: event.rows});
            })
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getDataList();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });     
    }

    handleDropdownChange(event) {
        const target = event;
        const inputName = target.target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });
    }

    removeData(dataId) {
        this.lgaService.removeHouseBill(dataId, "DELETE")
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.getDataList();
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    booleanTemplate(rowData, column) {
        return rowData['enabled'] === true ? 'Active' : 'Inactive';
    }

    cellTemplate(rowData, column) {
        return <span>{rowData['houseHolder'].cell.cellNumber} - {rowData['houseHolder'].cell.zone.name}</span>;
    }
    amountTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['amount']);
    }
    paidAmountTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['paidAmount']);
    }

    actionTemplate(rowData, column) {
        return <div style={{whiteSpace: "nowrap"}}>
        <Link to={"/wallet/lga/month/bills/" + rowData["holderId"]}><Button type="button" icon="pi pi-eye" className="p-button p-button-sm p-button-success" style={{marginRight: '.2em'}} title={this.props.t("View Month Bills")}/></Link>
        </div>;
    }

    export() {
        this.dt.exportCSV();
    }

    render() {

        let header = (
        <div style={{textAlign:'left'}}>
            <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
            <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.tableExcel(this.props.t("House Bills"), this.excelCols, this.state.dataTableValue)} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
            <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.printTable(this.props.t("List of House Bills"), this.exportColumns, this.state.dataTableValue)} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
        </div>
        );

        let nameFilter = <InputText type="text" name="name" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.name} onChange={this.handleInputChange}/>
        let paymentIdFilter = <InputText type="text" name="paymentId" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.paymentId} onChange={this.handleInputChange}/>
        let houseNumberFilter = <InputText type="text" name="houseNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.houseNumber} onChange={this.handleInputChange}/>
        let phoneNumberFilter = <InputText type="text" name="phoneNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.phoneNumber} onChange={this.handleInputChange}/>
        let cellFilter = <Dropdown name="cellId" style={{width: '100%'}} placeholder={this.props.t("Choose Cell")} options={this.state.cells} value={this.state.cellId} onChange={this.handleDropdownChange} optionLabel="name" optionValue="id" filter filterBy="name" autoWidth={false} />

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>{this.props.t('List of House Bills')}</h2></div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header={header} paginator={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})} 
                            currentPageReportTemplate={this.props.t("Data") + " " + ((this.state.pageIndex * this.state.pageSize) + 1) + " " + this.props.t("to") + " " + ((this.state.pageIndex * this.state.pageSize) + this.state.numberOfElements) + " " + this.props.t("of") + " " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30,50,100,[this.state.totalElements]]} onPage={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" lazy={true}>
                            <Column field="name" header={this.props.t("House Holder")} sortable={true} filter={true} filterElement={nameFilter}/>
                            <Column field="paymentId" header={this.props.t("Reference")} sortable={true} filter={true} filterElement={paymentIdFilter}/>
                            <Column field="houseNumber" header={this.props.t("House Number")} sortable={true} filter={true} filterElement={houseNumberFilter}/>
                            <Column field="phoneNumber" header={this.props.t("Phone Number")} sortable={true} filter={true} filterElement={phoneNumberFilter}/>
                            <Column field="cellNumber" header={this.props.t("Cell Number")} sortable={true} filter={true} filterElement={cellFilter}/>
                            {/* <Column field="paidAmount" header={this.props.t("Paid Amount")} sortable={true} filter={false}/> */}
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#90009E"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(HouseBills)
