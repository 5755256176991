import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ResponseAlert = (props) => {
    let {failed = null, alert = "", setFailed = () => {}} = props;

    const [status, setStatus] = useState(null);
    const [message, setMessage] = useState("");

    useEffect(() => {
        setStatus(failed);
        setMessage(alert);
    }, [failed, alert])

    return (
        <>
            { status !== null &&
            <div className={`p-messages ${status === true ? "p-messages-warn" : "p-messages-success"} p-component`} style={{margin: '0 0 1em 0', display: 'block'}}>
                <div className="p-messages-wrapper rounded">
                    <ul className="p-justify-center p-text-center">
                        <li className="p-justify-center p-text-center">
                            <span className={`p-messages-icon pi pi-fw pi-2x ${status === true ? "pi-exclamation-triangle" : "pi-check-circle"}`} style={{alignSelf: "flex-start"}}></span>
                            <span className="p-messages-detail">{message}</span>
                            <span style={{alignSelf: "flex-end"}}><Link type="button" onClick={() => setFailed(null)} className="p-message-icon pi pi-times-circle" style={{alignSelf: "flex-end", verticalAlign: "middle", fontSize: 26, marginRight: 2}} /></span>
                        </li>
                    </ul>
                </div>
            </div>
            }
        </>
    );
}

ResponseAlert.propTypes = {
    failed: PropTypes.bool,
    alert: PropTypes.string,
    setFailed: PropTypes.func,
};

export default ResponseAlert;