import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { WalletService } from '../../service/WalletService';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { withTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';

class BalanceSettlement extends Component {

	constructor(props) {
        super(props);
        this.state = {
            settlementAmount: 0,
            details: "",
            alert: ''
        };
        this.walletService = new WalletService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();
        const settlementJson = {
            settlementAmount: this.state.settlementAmount,
            details: this.state.details
        };
        if (JSON.parse(this.props.headerBalances).Fee > settlementJson.settlementAmount) {
            this.walletService.createSettlement(settlementJson)
            .then(response => {
                this.setState({failed: false});
                this.setState({alert: response.message});
                this.props.history.push("/wallet/settlements");
            }).catch(error => {
                this.setState({failed: true});
                this.setState({alert: error.message});
            });
        } else {
            this.setState({failed: true});
            this.setState({alert: "Settlement Amount must be lower than your balance"});
        }
	}

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/balance/settlement",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        { this.state.failed === true &&
                            <div className="p-messages p-component p-messages-warn" style={{margin: '0 0 1em 0', display: 'block'}}>
                                    <div className="p-messages-wrapper rounded">
                                        <ul>
                                            <li>
                                                <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                                <span className="p-messages-detail">{this.state.alert}</span>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        }

                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#90009E", color: "#FFFFFF", padding: "1%"}}>
                                <h2 style={{textAlign: "center"}}>{this.props.t('Withdraw Balance')}</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Withdraw Amount')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputNumber type="text" name="settlementAmount" placeholder={this.props.t("Enter") + " " + this.props.t("Amount")} value={this.state.settlementAmount} mode="decimal" minFractionDigits={2} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Withdraw Details')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="details" placeholder={this.props.t("Enter") + " " + this.props.t("Details")} value={this.state.details} mode="decimal" minFractionDigits={2} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label={this.props.t("Confirm Withdraw")} icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}

export default withTranslation()(BalanceSettlement)