import React, { Component } from 'react';
import {Chart} from 'primereact/chart';
import { HomeService } from '../service/HomeService';
import { ClientService } from '../service/ClientService';
import NumberFormat from 'react-number-format';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Panel } from 'primereact/panel';
import ReactECharts from 'echarts-for-react';

export default class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            months: 6,
            top: 10,
            barData: {
                labels: ['Tuition Fee', 'Transport Fee', 'Medical Fee', 'Stationery Fee', 'Graduation Fee', 'Tour Fee'],
                datasets: [
                    {
                        label: this.props.lang.t('Current Amount'),
                        backgroundColor: '#90009E',
                        borderColor: '#90009E',
                        data: [59, 80, 81, 56, 55, 40]
                    },
                    {
                        label: this.props.lang.t('Expected Amount'),
                        backgroundColor: '#E00000',
                        borderColor: '#E00000',
                        data: [48, 40, 19, 86, 27, 90]
                    }
                ]
            },
            pieData: {
                labels: ['2022-01', '2022-02', '2022-03', '2022-04','2022-05','2022-06'],
                datasets: [
                    {
                        data: [150, 200, 500, 300, 50, 100],
                        backgroundColor: ["#E00000", "#90009E", "#03A9F4", "#C75952", "#807BE8", "#74C1D6"],
                        hoverBackgroundColor: ["#FFE082", "#81D4FA", "#A5D6A7", "#FFE082", "#81D4FA", "#A5D6A7"]
                    }]
            },
            topClients: [],
            paymentsData: {
                income:[{name:"2022-01",value:0},{name:"2022-02",value:0},{name:"2022-03",value:0},{name:"2022-04",value:0},{name:"2022-05",value:0},{name:"2022-06",value:0}],
                month:["2022-01","2022-02","2022-03","2022-04","2022-05","2022-06"]
            },
            incomeData: {
                types:["2022-01","2022-02","2022-03","2022-04","2022-05","2022-06"],
                expected:[80,60,100,70,120,100],
                current:[50,60,100,15,90,150]
            }
        };
        this.homeService = new HomeService();
        this.clientService = new ClientService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    authorisedRole(roleName) {
        let roles = JSON.parse(this.props.roles);
        const isPresent = roles.some(function(el) {
            return el.name === roleName;
        });
        return isPresent;
    }

    componentDidMount() {
        this.homeService.getPaymentsAnalysis(this.state.months)
        .then(response => {
            this.setState({paymentsData: response});
        });
        if(!(this.authorisedRole("ADMIN") || this.authorisedRole("PARTNER"))) {
            if (this.props.category === "Schools") {
                this.homeService.getFeeAnalysis()
                .then(response => {
                    this.setState({incomeData: response});
                });
            } else if (this.props.category === "Local Government Authority") {
                this.homeService.getLgaBillsAnalysis(this.state.months)
                .then(response => {
                    this.setState({incomeData: response});
                });
            }
        }
        if(this.authorisedRole("ADMIN") || this.authorisedRole("PARTNER")) {
            this.clientService.getTopClients(this.state.top)
            .then(response => {
                this.setState({topClients: response});
            });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();
        this.homeService.getPaymentsAnalysis(this.state.months)
        .then(response => {
            this.setState({paymentsData: response});
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }
    
    amountTemplate(rowData, column) {
        return <span><NumberFormat value={rowData['amount']} displayType={'text'} thousandSeparator={true} prefix={'TZS'} />/=</span>
    }

    render(){

        const payments = {
            title : {
              text: this.props.lang.t('Payments'),
              subtext: this.props.lang.t(''),
              x:'center'
            },
            tooltip : {
              trigger: 'item',
              formatter: "{a} <br/>{b} : {c} ({d}%)"
            },
            legend: {
              orient: 'horizontal',
              left: 'center',
              top: 'bottom',
              data: this.state.paymentsData.month
            },
            toolbox: {
                show: true,
                feature: {
                    mark: {show: true},
                    restore: {
                        show: true,
                        title: 'refresh'
                    },
                    saveAsImage: {
                        show: true,
                        title: 'export'
                    }
                }
            },
            series : [
              {
              name: 'TZS',
              type: 'pie',
              radius : '55%',
              center: ['50%', '50%'],
              data: this.state.paymentsData.income,
              itemStyle: {
                emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0,161,75, 0.5)'
                }
              }
              }
            ]
        };

        const income = {
            tooltip: {},
            legend: {
              data:[this.props.lang.t('Expected Income'), this.props.lang.t('Current Income')]
            },
            toolbox: {
                show: true,
                feature: {
                    mark: {show: true},
                    saveAsImage: {
                        show: true,
                        title: 'export'
                    },
                    restore: {
                        show: true,
                        title: 'refresh'
                    },
                    magicType: {show: true, title: 'change', type: ['line', 'bar']}
                }
            },
            xAxis: {
                axisLabel: {
                    interval: 0,
                    rotate: 30,
                    textStyle: {
                      baseline: "top",
                      color: "#90009E",
                      fontSize: 12,
                      fontWeight: "bold"
                    }
                },
                data: this.state.incomeData?.types || this.state.incomeData.fee
            },
            yAxis: {
                axisLabel: {
                    rotate: 45
                }
            },
            series: [
                {
                    name: this.props.lang.t('Expected Income'),
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            color: 'rgba(57,163,244,1)',
                            label: {
                                show: true,
                                rotate: 60,
                                textStyle: {color: '#FFFFFF'}
                            }
                        }
                    },
                    data: this.state.incomeData.expected
                },
                {
                    name: this.props.lang.t('Current Income'),
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            color: 'rgba(144,0,158,1)',
                            label: {
                                show: true,
                                rotate: 60,
                                textStyle: {color: '#FFFFFF'}
                            }
                        }
                    },
                    data: this.state.incomeData.current
                }
            ]
          };

        return <div className="p-grid dashboard">
            <div className="p-col-12 p-md-4">
                <div className="overview-box overview-box-main"><h1 style={{color: "#90009E"}}>{this.props.lang.t('MAIN BALANCE')}</h1>
                    <div className="overview-value">
                        <NumberFormat value={JSON.parse(this.props.headerBalances).Fee} displayType={'text'} thousandSeparator={true} prefix={'TZS'} style={{color: "#90009E"}} />/=
                    </div>
                    <img src="assets/layout/images/dashboard/graph-blue.svg" alt="adalipa-layout"/>
                </div>
            </div>
            <div className="p-col-12 p-md-4">
                <div className="overview-box overview-box-oc">
                    <h1 style={{color: "#90009E"}}>{this.props.lang.t('OTHER COLLECTIONS')}</h1>
                    <div className="overview-value">
                        <NumberFormat value={JSON.parse(this.props.headerBalances).OCs} displayType={'text'} thousandSeparator={true} prefix={'TZS'} style={{color: "#90009E"}} />/=
                    </div>
                    <img src="assets/layout/images/dashboard/graph-blue.svg" alt="adalipa-layout"/>
                </div>
            </div>
            <div className="p-col-12 p-md-4">
                <div className="overview-box overview-box-sms">
                    <h1 style={{color: "#90009E"}}>{this.props.lang.t('SMS BALANCE')}</h1>
                    <div className="overview-value">
                        <NumberFormat value={JSON.parse(JSON.parse(this.props.headerBalances).Sms).sms} displayType={'text'} thousandSeparator={true} prefix={''} style={{color: "#90009E"}} /> <span style={{color: "#90009E"}}>Messages</span>
                    </div>
                    <img src="assets/layout/images/dashboard/graph-red.svg" alt="adalipa-layout"/>
                </div>
            </div>
            <div className="p-col-12 p-md-3">
                {/* <div className="card">
                    <h2 className="centerText">{this.props.lang.t('Payments Analysis')}</h2>
                    <Chart type="pie" data={this.state.pieData} height="150"/>
                </div> */}
                <Panel header={this.props.lang.t("Monthly Transactions Chart")} style={{height: "100%"}}>
                    <ReactECharts option={payments} style={{ height: 400 }} />
                </Panel>
            </div>
            { !(this.authorisedRole("ADMIN") || this.authorisedRole("PARTNER")) &&
                <div className="p-col-12 p-md-9">
                    <Panel header={this.props.lang.t("Income Statistics")} className="disk-spaces" style={{height: "100%"}}>
                        <ReactECharts option={income} style={{ height: 400 }} opts={{ renderer: 'svg' }} />
                    </Panel>
                </div>
            }

            { (this.authorisedRole("ADMIN") || this.authorisedRole("PARTNER")) &&
                <div className="p-col-12 p-md-3">
                    <div className="card">
                        <h2 className="centerText">Top {this.state.top} Clients By Collections</h2>
                        <DataTable ref={(el) => this.dt = el} value={this.state.topClients} selectionMode="single" paginator={true} rows={10} responsive={true}>
                            <Column field="name" header="Client" sortable={false} filter={false}/>
                            <Column field="amount" body={this.amountTemplate} header="Collections" sortable={false} filter={false}/>
                        </DataTable>
                    </div>
                </div>
            }
        </div>
    }
}
