import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { UserService } from '../service/UserService';
import ResponseAlert from '../Utilities/ResponseAlert';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";

class ResetingPassword extends Component {

	constructor(props) {
        super(props);
        this.state = {
            dataJson: {password: "", confirm: ""},
            failed: null,
            alert: '',
            loading: false
        };
        this.userService = new UserService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount() {
        if (this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        if (+this.props.match.params.dataId > 0) {
            this.setState({dataId: +this.props.match.params.dataId});
            this.lgaService.getZone(+this.props.match.params.dataId)
            .then(data => {
                this.setState({dataJson: {id: data.id, name: data.name, details: data.details}});
            });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState(prevJson => ({
            dataJson: {
                ...prevJson.dataJson, [inputName] : inputValue
            }
        }));        
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        if(this.state.password === this.state.confirm) {
            this.userService.resetPassword(this.state.dataJson)
            .then(response => {
                this.setState({loading: false});
                this.setState({failed: false});
                this.setState({alert: response.message});
                this.props.history.push("/");
            }).catch(error => {
                this.setState({loading: false});
                this.setState({failed: true});
                this.setState({alert: error.message});
            });
        } else {
            this.setState({loading: false});
            this.setState({failed: true});
            this.setState({alert: "Password does not match!"});
        }
	}

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/",
				state: { from: this.props.location }
			}}/>;
		}

        return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <ResponseAlert failed={this.state.failed} alert={this.state.alert} />
                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#90009E", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>{this.props.t("Reset")} {this.props.t("Password")}</h2>
                            </div>
                            {this.state.loading && 
                                <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                    <div className="p-col-12" style={{textAlign:'center'}}>
                                        <BeatLoader color={"#90009E"} loading={this.state.loading} css={""} size={10} />
                                    </div>
                                </div>
                            }
                            {!this.state.loading && 
                            <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-md-offset-1 p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('New Password')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="password" name="password" placeholder={this.props.t("Enter") + " " + this.props.t("Password")} value={this.state.dataJson.password} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Confirm')} {this.props.t('Password')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="password" name="confirm" placeholder={this.props.t("Confirm") + " " + this.props.t("Password")} value={this.state.dataJson.confirm} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label={this.props.t("Save")} icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ResetingPassword);
