import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { HomeService } from '../service/HomeService';
import { ClientService } from '../service/ClientService';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

export default class AddFeeType extends Component {

	constructor(props) {
        super(props);
        this.state = {
            feeType: '',
            currency: 'Tsh',
            description: 'N/A',
			alert: ''
        };
        this.homeService = new HomeService();
        this.clientService = new ClientService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();
        const typeJson = {
            feeType: this.state.feeType,
            currency: this.state.currency,
            description: this.state.description
        };
        this.clientService.addFeeType(typeJson)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/fee/types");
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/fee/add-fee-type",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-messages p-component p-messages-success" style={{margin: '0 0 1em 0', display: 'block'}}>
                            { this.state.failed === true &&
                                <div className="p-messages-wrapper rounded">
                                    <ul>
                                        <li>
                                            <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                            <span className="p-messages-detail">{this.state.alert}</span>
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>

                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#90009E", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>New Fee Type</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Fee Type:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="feeType" placeholder="eg: Tuition Fee" value={this.state.feeType} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Currency:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="currency" placeholder="eg: Tsh" value={this.state.currency} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Description:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="description" placeholder="More Details" value={this.state.description} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label="Add Fee Type" icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}