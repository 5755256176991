const API_BASE_URL = 'https://core.adalipa.co.tz:8443';
// export const API_BASE_URL = 'http://154.74.133.74:8844/adalipa';
// const API_BASE_URL = 'http://127.0.0.1:8686';
export const CLIENT = API_BASE_URL + '/client';
export const CLIENT_DETAILS = API_BASE_URL + '/client/details';
export const BALANCE = API_BASE_URL + '/balance';
export const ACADEMIC = API_BASE_URL + '/academic';
export const DOCUMENTS = API_BASE_URL + '/document';
export const STUDENT = API_BASE_URL + '/student';
export const PARTNER = API_BASE_URL + '/partner';
export const PARTNER_DETAILS = API_BASE_URL + '/partner/details';
export const SMS_NOTIFICATION = API_BASE_URL + '/notifications';
export const CONTRIBUTION = API_BASE_URL + '/contribution';
export const CONTRIBUTION_GROUP = API_BASE_URL + '/contribution/group';
export const CONTRIBUTION_MEMBER = API_BASE_URL + '/contribution/member';
export const REAL_ESTATE = API_BASE_URL + '/real/estate';
export const OTHER_BUSINESS = API_BASE_URL + '/other/business';
export const OTHER_BUSINESS_BUYER = API_BASE_URL + '/other/business/buyer';
export const LGA = API_BASE_URL + '/lga';
export const ZONE = API_BASE_URL + '/lga/zones';
export const CELL = API_BASE_URL + '/lga/cells';
export const CELL_LEADER = API_BASE_URL + '/lga/cell/leaders';
export const HOUSE_HOLDER = API_BASE_URL + '/lga/house/holders';
export const HOUSE_BILL = API_BASE_URL + '/lga/house/bills';
export const MONTH_BILL = API_BASE_URL + '/lga/month/bills';