import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { HomeService } from '../service/HomeService';
import { ClientService } from '../service/ClientService';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

const stringValidation = (fieldName, fieldValue) => {
    if (fieldValue.trim() === '') {
      return `${fieldName} is required`;
    }
    if (/[^a-zA-Z -]/.test(fieldValue)) {
      return 'Invalid string';
    }
    if (fieldValue.trim().length < 3) {
      return `${fieldName} needs to be at least three characters`;
    }
    return null;
  };
  
  const numberValidation = (fieldName, fieldValue) => {
    if (fieldValue.trim() === 0) {
      return `${fieldName} is required`;
    }
    if (/[^0-9-]/.test(fieldValue)) {
      return 'Invalid number';
    }
    return null;
  };
  
  const emailValidation = email => {
    if (
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      )
    ) {
      return null;
    }
    if (email.trim() === '') {
      return 'Email is required';
    }
    return 'Please enter a valid email';
  };

export default class UpdateClient extends Component {

	constructor(props) {
        super(props);
        this.state = {
            clientId: 0,
            institute_name: '',
            region_id: 2,
            reg_no: '',
            address: '',
            regions: [],
            categories: [],
            failed: undefined,
			alert: ''
        };
        this.homeService = new HomeService();
        this.clientService = new ClientService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        this.clientService.getClientDetails(this.props.match.params.clientid)
        .then(data => {
            this.setState({
                institute_name: data.instituteName,
                region_id: data.region.id,
                address: data.address,
                reg_no: data.regNo
            })
        });
        this.homeService.getRegions('none', 0, 100, 'id', 'asc')
        .then(response => {
            const data = response.content;
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.regionName
            }))
            this.setState({regions: options});
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();
        const clientJson = {
            clientId: this.props.match.params.clientid,
            institute_name: this.state.institute_name,
            region_id: this.state.region_id,
            reg_no: this.state.reg_no,
            address: this.state.address
        };
        this.clientService.updateClient(clientJson)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.clientService.getClientDetails(this.props.match.params.clientid)
            .then(data => {
                this.setState({
                    institute_name: data.instituteName,
                    region_id: data.region.id,
                    address: data.address,
                    reg_no: data.regNo
                })
            });
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/client/list-clients",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-messages p-component p-messages-warning" style={{margin: '0 0 1em 0', display: 'block'}}>
                            { this.state.failed === true &&
                                <div className="p-messages-wrapper rounded">
                                    <ul>
                                        <li>
                                            <span className="p-messages-icon pi pi-fw pi-2x pi-exclamation-triangle"></span>
                                            <span className="p-messages-detail">{this.state.alert}</span>
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>
                        <div className="p-messages p-component p-messages-success" style={{margin: '0 0 1em 0', display: 'block'}}>
                            { this.state.failed === false &&
                                <div className="p-messages-wrapper rounded">
                                    <ul>
                                        <li>
                                            <span className="p-messages-icon pi pi-fw pi-2x pi-check-circle"></span>
                                            <span className="p-messages-detail">{this.state.alert}</span>
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>

                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#90009E", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>Update {this.state.institute_name}</h2>
                            </div>
                            
                            <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="input">Institution Name:</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText type="text" name="institute_name" placeholder="eg: DataVision International" value={this.state.institute_name} onChange={this.handleInputChange} required/>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="dropdown">Region: </label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown options={this.state.regions} value={this.state.region_id} onChange={event => this.setState({region_id: event.value})} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="input">Registration Number: </label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText type="text" name="reg_no" placeholder="eg: S0108" value={this.state.reg_no} onChange={this.handleInputChange} required/>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="input">Address: </label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText type="text" name="address" placeholder="eg: Ubungo, Dar es Salaam" value={this.state.address} onChange={this.handleInputChange} required/>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label="Update Client" icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}