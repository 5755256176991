import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { WalletService } from '../service/WalletService';
import { AdalipaUtil } from '../Utilities/AdalipaUtil';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";

class ListCollections extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue:[],
            excelData:[],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            walletName: '',
            walletCode: '',
            walletAccount: '',
            Status: null,
            sortOrder: -1,
            sortField: 'id',
            loading: false
        };

        this.walletService = new WalletService();
        this.adalipaUtil = new AdalipaUtil();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.export = this.export.bind(this);

        this.cols = [
            { field: 'walletName', header: 'Collection' },
            { field: 'walletCode', header: 'Wallet Code' },
            { field: 'walletAccount', header: 'Payment Reference' },
            { field: 'description', header: 'Description' },
            { field: 'enabled', header: 'Enabled' }
        ];
        this.excelCols = {header: ['walletName', 'walletCode', 'walletAccount', 'description', 'enabled']};
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }

    componentDidMount() {
        this.setState({loading: true});
        this.walletService.getCollections(this.state.walletName, this.state.walletCode, this.state.walletAccount, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
        .then(data => {
            this.setState({loading: false});
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
            const excelOptions = data.content.map(d => ({
                walletName: d.walletName, walletCode: d.walletCode, walletAccount: d.walletAccount, description: d.description, enabled: d.enabled ? "Yes" : "No"
            }));
            this.setState({excelData: excelOptions});
        });
    }

    onPageChange(event) {
        this.setState({loading: true});
        this.setState({pageIndex: event.page}, () => {
        this.walletService.getCollections(this.state.walletName, this.state.walletCode, this.state.walletAccount, this.state.pageIndex, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
        .then(data => {
            this.setState({loading: false});
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
            this.setState({first: event.first, pageSize: event.rows});
            const excelOptions = data.content.map(d => ({
                walletName: d.walletName, walletCode: d.walletCode, walletAccount: d.walletAccount, description: d.description, enabled: d.enabled ? "Yes" : "No"
            }));
            this.setState({excelData: excelOptions});
        });
        });
    }

    onSortChange(event) {
        this.setState({loading: true});
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.walletService.getCollections(this.state.walletName, this.state.walletCode, this.state.walletAccount, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({loading: false});
                this.setState({dataTableValue: data.content});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
                const excelOptions = data.content.map(d => ({
                    walletName: d.walletName, walletCode: d.walletCode, walletAccount: d.walletAccount, description: d.description, enabled: d.enabled ? "Yes" : "No"
                }));
                this.setState({excelData: excelOptions});
            });
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.setState({loading: true});
            this.walletService.getCollections(this.state.walletName, this.state.walletCode, this.state.walletAccount, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({loading: false});
                this.setState({dataTableValue: data.content});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
                const excelOptions = data.content.map(d => ({
                    walletName: d.walletName, walletCode: d.walletCode, walletAccount: d.walletAccount, description: d.description, enabled: d.enabled ? "Yes" : "No"
                }));
                this.setState({excelData: excelOptions});
            });
        });     
    }

    booleanTemplate(rowData, column) {
        return rowData['enabled'] === true ? 'Yes' : 'No';
    }

    actionTemplate(rowData, column) {
        return <div>
            <Link to={"/wallet/funds/transfer/" + rowData["id"]}><Button type="button" icon="pi pi-money-bill" className="p-button-warning"/></Link>
        </div>;
    }

    export() {
        this.dt.exportCSV();
    }

    render() {

        let header = <div style={{textAlign:'left'}}>
            <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
            <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.tableExcel("Collections", this.excelCols, this.state.dataTableValue)} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
            <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.printTable("List of Collections", this.exportColumns, this.state.dataTableValue)} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
        </div>
        let walletNameFilter = <InputText type="text" name="walletName" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.walletName} onChange={this.handleInputChange}/>
        let walletCodeFilter = <InputText type="text" name="walletCode" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.walletCode} onChange={this.handleInputChange}/>
        let walletAccountFilter = <InputText type="text" name="walletAccount" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.walletAccount} onChange={this.handleInputChange}/>

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>{this.props.t('Collections')}</h2></div>
                            <div className="p-col-8 p-md-8">
                                <Link to="/wallet/add/collection" style={{float: "right"}}><Button label={this.props.t("Add Collection")} icon="pi pi-plus"/></Link>
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header={header} paginator={true} 
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange} responsive={true} 
                            selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})} 
                            currentPageReportTemplate={this.props.t("Data") + " " + ((this.state.pageIndex * this.state.pageSize) + 1) + " " + this.props.t("to") + " " + ((this.state.pageIndex * this.state.pageSize) + this.state.numberOfElements) + " " + this.props.t("of") + " " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,50,100,[this.state.totalElements]]} onPage={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" lazy={true}>
                            <Column field="walletName" header={this.props.t("Collection")} sortable={true} filter={true} filterElement={walletNameFilter} filterPlaceholder="Contains"/>
                            <Column field="walletCode" header={this.props.t("Wallet Code")} sortable={true} filter={true} filterElement={walletCodeFilter} filterPlaceholder="Contains"/>
                            <Column field="walletAccount" header={this.props.t("Payment Reference")} sortable={true} filter={true} filterElement={walletAccountFilter} filterPlaceholder="Contains"/>
                            <Column field="description" header={this.props.t("Description")} sortable={true} filter={false}/>
                            <Column field="enabled" body={this.booleanTemplate} header={this.props.t("Enabled")} sortable={true} filter={false}/>
                            <Column header={this.props.t("Actions")} body={this.actionTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#90009E"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(ListCollections)