import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { ClientService } from '../service/ClientService';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

export default class AddStudentBatch extends Component {

	constructor(props) {
        super(props);
        this.state = {
            file: '',
            levelId: 1,
			alert: '',
            levels: []
        };
        this.clientService = new ClientService();
        this.handleLevelChange = this.handleLevelChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        this.clientService.getLevels('', 0, 100, 'id', 'asc')
        .then(response => {
            const data = response.content;
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.gradeName + (d.course === null ? "" : " - " + d.course.courseName + "")
            }))
            this.setState({levels: options});
        });
    }

    handleLevelChange = event => { 
        this.setState({levelId: event.value});
    }
    handleFileChange = event => {
        this.setState({ file: event.target.files[0] });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.clientService.addStudentBatch(this.state.file, this.state.levelId)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/student/list-students");
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/student/list-students",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-messages p-component p-messages-warn" style={{margin: '0 0 1em 0', display: 'block'}}>
                            { this.state.failed === true &&
                                <div className="p-messages-wrapper rounded">
                                    <ul>
                                        <li>
                                            <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                            <span className="p-messages-detail">{this.state.alert}</span>
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>

                        <div className="card card-w-title">
                            <div className="p-grid">
                                <div className="p-col-4 p-md-4"><h1>New Students</h1></div>
                                <div className="p-col-8 p-md-8">
                                    <Link to="/student/add-student" style={{float: "right"}}><Button label="Add Student" icon="pi pi-plus"/></Link>
                                </div>
                            </div>
                            <div className="p-card-title" style={{backgroundColor: "#90009E", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>Add New Students Using Excel File</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-12" style={{textAlign: 'center'}}><span>Excel Arrangements</span></div>
                                    <div className="p-col-12 p-md-12" style={{textAlign: 'center'}}>
                                        <img src="assets/layout/images/ExcelFormat.png" alt="M-Lipa" className="logo"/>
                                    </div>
                                </div>
                                <div className="p-grid" style={{padding: '2%'}}>
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="dropdown">Level: <span style={{color: '#E00000'}}>*</span></label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.levels} value={this.state.levelId} onChange={this.handleLevelChange} autoWidth={false} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">Excel File: <span style={{color: '#E00000'}}>*</span></label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <input type="file" name="file" onChange={this.handleFileChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label="Add Students" icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}