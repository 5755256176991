import React from 'react';

const ViewDetails = (props) => {
    return (
        <div className="p-col-12" style={{border: "1px solid #90009E"}}>
            <div className="p-fluid p-grid">
                { props.attributes.length > 0 ? props.attributes.map((item) => (
                    <div className="p-field p-grid p-col-12 p-md-6">
                        <div className="p-col-12 p-mb-2 p-md-4 p-mb-md-0 p-text-right" style={{textAlign: "left", paddingLeft: "1em"}}>
                            <label htmlFor={item.name} className="p-float-right">{item.title}:</label>
                        </div>
                        <div className="p-col-12 p-md-8 p-text-left p-float-left">
                            {item.name}
                        </div>
                    </div>
                )) : ""}
            </div>
        </div>
    )
}

export default ViewDetails;
