import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { WalletService } from '../../service/WalletService';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';
import { Dropdown } from 'primereact/dropdown';

export default class EditCustomer extends Component {

	constructor(props) {
        super(props);
        this.state = {
            customerName: '',
            phoneNumber: '',
            customerId: 0,
            business: {id: 0},
            businesses: [],
            fee: 0,
            amt: 0,
            alert: ''
        };
        this.walletService = new WalletService();
        this.adalipaUtil = new AdalipaUtil();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        this.walletService.buyerInfo(this.props.match.params.customerid, "GET")
        .then(response => {
            this.setState({
                customerId: response?.buyer?.id,
                customerName: response?.buyer?.customerName,
                phoneNumber: response?.buyer?.phoneNumber,
                business: {id: response?.buyer?.buyerBusiness?.id || null},
                fee: response.feeAmt,
                amt: response.paidAmt
            });
        });
        this.walletService.getBusinesses('', '', 0, 100, 'id', 'asc')
        .then(response => {
            const data = response.content;
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.business
            }))
            this.setState({businesses: options});
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();
        let buyerJson = {
            customerId: this.state.customerId,
            business: this.state.business,
            customerName: this.state.customerName,
            phoneNumber: this.state.phoneNumber,
            fee: +this.state.fee.toString().replace(",", "").replace("undefined", ""),
            amt: +this.state.amt.toString().replace(",", "").replace("undefined", "")
        };
        this.walletService.buyer(buyerJson, 'PUT')
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/business/list/customers");
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/business/edit/customer",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        { this.state.failed === true &&
                            <div className="p-messages p-component p-messages-warn" style={{margin: '0 0 1em 0', display: 'block'}}>
                                    <div className="p-messages-wrapper rounded">
                                        <ul>
                                            <li>
                                                <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                                <span className="p-messages-detail">{this.state.alert}</span>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        }

                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#90009E", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>Edit Customer</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Customer Name:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="customerName" placeholder="eg: Form One" value={this.state.customerName} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Phone Number:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="phoneNumber" placeholder="eg: 255750000000" value={this.state.phoneNumber} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="dropdown">Business: </label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.businesses} value={this.state.business.id} onChange={event => this.setState({business: {id: event.value}})} placeholder="Choose Business" autoWidth={false}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Fee Amount (TZS):</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="fee" placeholder="eg: 255750000000" value={this.adalipaUtil.toDecimal(this.state.fee)} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Paid Amount (TZS):</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="amt" placeholder="eg: 255750000000" value={this.adalipaUtil.toDecimal(this.state.amt)} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label="Save" icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}