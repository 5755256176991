import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './AppBreadcrumb.css';
import { signout } from './service/LoginService';
import NumberFormat from 'react-number-format';
import { ClientService } from './service/ClientService';
import { HEADER_BALANCES } from './constants';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';

export class AppBreadcrumb extends Component {

    static propTypes = {
        match: PropTypes.object
    }

    constructor(props) {
        super(props);
        // eslint-disable-next-line no-unused-expressions
        this.refreshBalancesInterval;
    }

    componentDidMount() {
        this.refreshBalancesInterval = setInterval(() => {
            this.refreshBalances();
        }, 300000);
    }

    componentWillUnmount(){
        clearInterval(this.refreshBalancesInterval);
     }

    refreshBalances() {
        const clientService = new ClientService();
        clientService.getBalances()
        .then(response => {
            localStorage.setItem(HEADER_BALANCES, JSON.stringify(response));
            this.props.changeHeaderBalances();
        }).catch(error => {
			console.log("Failed to get header balances");
		})
    }

    handleLogout() { 
        signout()
        .then(response => {
            localStorage.clear();
			this.setState({failed: false});
			this.setState({alert: "You're successfully logged out!"});
            this.props.history.push("/login");
		    // window.location.assign('https://softwaregalaxy.co.tz/_s/auth/logout?xc=26b760c5-4f8b-4a28-917e-a30b0db1d2bf&callback=https://core.adalipa.co.tz:8443/galaxy/token');
        }).catch(error => {
            localStorage.clear();
			this.setState({failed: true});
			this.setState({alert: "You're successfully logged out!"});
            this.props.history.push("/login");
		    // window.location.assign('https://softwaregalaxy.co.tz/_s/auth/logout?xc=26b760c5-4f8b-4a28-917e-a30b0db1d2bf&callback=https://core.adalipa.co.tz:8443/galaxy/token');
        });
    }

    render() {
        const { location } = this.props;
        const paths = location.pathname.split('/');
        return (
            <div className="layout-breadcrumb">
                <ul className="hidden-mobile">
                    <li><button className="p-link"><i className="pi pi-home"></i></button></li>
                    {
                        location.pathname === '/' ?  <li>/</li> : paths.map((path, index) => <li key={index}>{path === '' ? '' : '/' + path}</li>)
                    }
                </ul>
                { this.props.headerBalances !== "{}" &&
                    <span style={{marginLeft: "1%", verticalAlign: "middle", fontSize: "normal"}}>
                        <strong style={{color: "#3B007B"}}>{this.props.lang.t('Main')}: </strong>
                        <strong style={{color: "#E00000"}}><NumberFormat value={JSON.parse(this.props.headerBalances).Fee} displayType={'text'} thousandSeparator={true} prefix={'TZS'} />/=</strong>
                        <strong>&nbsp;&nbsp;|&nbsp;&nbsp;</strong>
                        <strong style={{color: "#3B007B"}}>{this.props.lang.t('Collections')}: </strong>
                        <strong style={{color: "#E00000"}}><NumberFormat value={JSON.parse(this.props.headerBalances).OCs} displayType={'text'} thousandSeparator={true} prefix={'TZS'} />/=</strong>
                        <strong>&nbsp;&nbsp;|&nbsp;&nbsp;</strong>
                        <strong style={{color: "#3B007B"}}>{this.props.lang.t('Sms Balance')}: </strong>
                        <strong style={{color: "#E00000"}}><NumberFormat value={JSON.parse(JSON.parse(this.props?.headerBalances || {"Fee":0,"Sms":"{\"sms\":0,\"funds\":0}","OCs":0})?.Sms || {"sms":0,"funds":0})?.sms} displayType={'text'} thousandSeparator={true} prefix={''} /> {this.props.lang.t('sms')}</strong>
                        <strong className="hidden-mobile">&nbsp;&nbsp;|&nbsp;&nbsp;</strong>
                        <Link to="/sms/payments/history" className="hidden-mobile"><Button className="p-button-raised p-button-rounded div-rounded" label={this.props.lang.t("Buy SMS")} /></Link>
                    </span>
                }

                {/* <span style={{color: "black", marginLeft: "5%", verticalAlign: "middle", fontSize: "large"}}>{this.props.instituteName}</span> */}

                <div className="layout-breadcrumb-options">
                    <button className="p-link p-link-primary" title="Refresh Balances" onClick={() => this.refreshBalances()}>
                        <i className="pi pi-refresh"></i>
                    </button>
                    <button className="p-link p-link-primary" title="Statistics">
                        <i className="pi pi-chart-bar"></i>
                    </button>
                    <button className="p-link p-link-primary" title="Reports">
                        <i className="pi pi-briefcase"></i>
                    </button>
                    <button className="p-link p-link-danger" onClick={() => this.handleLogout()} title="Logout">
                        <i className="pi pi-power-off"></i>
                    </button>
                </div>
            </div>
        );
    }
}
