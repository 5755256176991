import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { ClientService } from '../service/ClientService';
import { FileUpload } from 'primereact/fileupload';

export default class UpdateDocument extends Component {

	constructor(props) {
        super(props);
        this.state = {
            file: '',
            documentName: '',
			alert: '',
            levels: []
        };
        this.clientService = new ClientService();
        this.documentUploadHandler = this.documentUploadHandler.bind(this);
	}
	
	async componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        this.setState({documentName: this.props.match.params.docType});
    }

    documentUploadHandler = ({files}) => {
        const [file] = files;
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            this.clientService.addDocument(file, this.state.documentName)
            .then(response => {
                this.setState({failed: false});
                this.setState({alert: response.message});
                this.props.history.push("/client/list/documents");
            }).catch(error => {
                this.setState({failed: true});
                this.setState({alert: error.message});
            });
        };
        fileReader.readAsDataURL(file);
    };

	render() {
        const {documentName} = this.state;
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/client/list/documents",
				state: { from: this.props.location }
			}}/>;
		}
		return !!documentName &&
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-messages p-component p-messages-warn" style={{margin: '0 0 1em 0', display: 'block'}}>
                            { this.state.failed === true &&
                                <div className="p-messages-wrapper rounded">
                                    <ul>
                                        <li>
                                            <span className="p-messages-icon pi pi-fw pi-2x pi-exclamation-triangle"></span>
                                            <span className="p-messages-detail">{this.state.alert}</span>
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>

                        <div className="card card-w-title">
                            <div className="p-grid">
                                <div className="p-col-4 p-md-4"><h1>Update {this.state.documentName} File</h1></div>
                                <div className="p-col-8 p-md-8">
                                </div>
                            </div>
                            <div className="p-card-title" style={{backgroundColor: "#90009E", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>Upload Pdf File</h2>
                            </div>
                            <form>
                                <div className="p-grid" style={{padding: '2%'}}>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="calendar" style={{float: "right", fontWeight: "bold"}}>PDF Document: <span style={{color: "#E00000"}}>*</span></label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <FileUpload  name="file" 
                                        customUpload={true}
                                        mode="advanced" auto={false} chooseLabel="Choose Pdf Document from your files"
                                        uploadHandler={this.documentUploadHandler} required/>
                                    </div>
                                </div>
                                {/* <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label="Update Document" icon="pi pi-plus" />
                                    </div>
                                </div> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
	}
}