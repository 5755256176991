import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { ClientService } from '../service/ClientService';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { format } from 'date-fns';

export default class AddLevelFee extends Component {

	constructor(props) {
        super(props);
        this.state = {
            levelId: 1,
            feeTypeId: 1,
            amount: 0,
            startDate: '',
            endDate: '',
			alert: '',
            levels: [],
            feeTypes: []
        };
        this.clientService = new ClientService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        this.clientService.getLevels('', 0, 100, 'id', 'asc')
        .then(response => {
            const data = response.content;
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.gradeName + (d.course === null ? "" : " (" + d.course.courseCode + ")")
            }))
            this.setState({levels: options});
        });
        this.clientService.getFeeTypes('none', 0, 100, 'id', 'asc')
        .then(response => {
            const data = response.content;
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.feeType
            }))
            this.setState({feeTypes: options});
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();
        const feeJson = {
            levelId: this.state.levelId,
            feeTypeId: this.state.feeTypeId,
            amount: this.state.amount,
            startDate: this.state.startDate,
            endDate: this.state.endDate
        };
        this.clientService.addSettings(feeJson)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/fee/levels");
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/fee/levels",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-messages p-component p-messages-success" style={{margin: '0 0 1em 0', display: 'block'}}>
                            { this.state.failed === true &&
                                <div className="p-messages-wrapper rounded">
                                    <ul>
                                        <li>
                                            <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                            <span className="p-messages-detail">{this.state.alert}</span>
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>

                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#90009E", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>Add Leve Fee</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="dropdown">Level:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.levels} value={this.state.levelId} onChange={event => this.setState({levelId: event.value})} autoWidth={false} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="dropdown">Fee Type: </label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Dropdown options={this.state.feeTypes} value={this.state.feeTypeId} onChange={event => this.setState({feeTypeId: event.value})} autoWidth={false}/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Amount:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="amount" placeholder="eg: 1500000" value={this.state.amount} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">Start From:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Calendar name="startDate" placeholder="Choose Date" value={this.state.startDate} onChange={(e) => this.setState({startDate: format(e.value, 'yyyy-MM-dd')})}/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">End To:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Calendar name="endDate" placeholder="Choose Date" value={this.state.endDate} onChange={(e) => this.setState({endDate: format(e.value, 'yyyy-MM-dd')})}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label="Add Fee to Level" icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}