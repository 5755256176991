import { CLIENT, CLIENT_DETAILS, ACADEMIC, DOCUMENTS, STUDENT, SMS_NOTIFICATION, BALANCE } from '../api';
import { API_BASE_URL } from '../constants';
import { postRequest, getRequest, postFiles } from './CoreService';

export class ClientService {
    completeRegistration(clientJson) {
        return postRequest({
            url: CLIENT + "/complete/registration",
            method: 'POST',
            body: JSON.stringify(clientJson)
        });
    }

    addClient(clientJson) {
        return postRequest({
            url: CLIENT_DETAILS,
            method: 'POST',
            body: JSON.stringify(clientJson)
        });
    }

    updateClient(clientJson) {
        return postRequest({
            url: CLIENT_DETAILS,
            method: 'PUT',
            body: JSON.stringify(clientJson)
        });
    }

    updateClientActivation(clientJson) {
        return postRequest({
            url: CLIENT + "/update/client/activation",
            method: 'POST',
            body: JSON.stringify(clientJson)
        });
    }

    getClientDetails(clientId) {
        return getRequest({ url: CLIENT_DETAILS + "?clientId=" + clientId, method: 'GET' });
    }

    getClientBalances(clientId) {
        return getRequest({ url: BALANCE + "/client/balances/" + clientId, method: 'GET' });
    }

    getClients(instituteName, instituteCode, mlipaAccount, page, limit, sort, order) {
        return getRequest({
            url: CLIENT + "/list?instituteName=" + instituteName + "&instituteCode=" + instituteCode + "&mlipaAccount=" + mlipaAccount + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    getIncompleteClients(fullName, email, phoneNumber, page, limit, sort, order) {
        return getRequest({
            url: CLIENT + "/incomplete/registrations?fullName=" + fullName + "&email=" + email + "&phoneNumber=" + phoneNumber + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    getTopClients(top) {
        return getRequest({
            url: CLIENT + "/best/performing?top=" + top,
            method: 'GET'
        });
    }

    getBalances() {
        return getRequest({
            url: API_BASE_URL + "/balance/header/balances",
            method: 'GET'
        });
    }

    addFeeType(feeTypeJson) {
        return postRequest({
            url: API_BASE_URL + "/fee/add/fee/type",
            method: 'POST',
            body: JSON.stringify(feeTypeJson)
        });
    }
    getFeeTypes(serachKey, page, limit, sort, order) {
        return getRequest({
            url: API_BASE_URL + "/fee/get/fee/types?serachKey=" + serachKey + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    addSettings(feeJson) {
        return postRequest({
            url: API_BASE_URL + "/fee/add/level/fee",
            method: 'POST',
            body: JSON.stringify(feeJson)
        });
    }
    getFeeSettings(serachKey, page, limit, sort, order) {
        return getRequest({
            url: API_BASE_URL + "/fee/get/fee/settings?serachKey=" + serachKey + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    addCourse(feeJson) {
        return postRequest({
            url: ACADEMIC + "/add/course",
            method: 'POST',
            body: JSON.stringify(feeJson)
        });
    }
    getCourses(serachKey, page, limit, sort, order) {
        return getRequest({
            url: ACADEMIC + "/get/courses?serachKey=" + serachKey + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }
    getAllCourses(courseName, courseCode, page, limit, sort, order) {
        return getRequest({
            url: ACADEMIC + "/get/courses?courseName=" + courseName + "&courseCode=" + courseCode + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    addLevel(feeJson) {
        return postRequest({
            url: ACADEMIC + "/add/level",
            method: 'POST',
            body: JSON.stringify(feeJson)
        });
    }
    getLevels(serachKey, page, limit, sort, order) {
        return getRequest({
            url: ACADEMIC + "/get/levels?serachKey=" + serachKey + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    //Documents
    addDocument(pdfFile, documentName) {
        const formData = new FormData();
        formData.append('file', pdfFile);
        formData.append('documentName', documentName);
        return postFiles({
            url: DOCUMENTS + "/add/document",
            method: 'POST',
            body: formData
        });
    }

    getDocuments(page, limit, sort, order) {
        return getRequest({
            url: DOCUMENTS + "/get/documents?page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    getClientDocuments(clientId, page, limit, sort, order) {
        return getRequest({
            url: DOCUMENTS + "/get/documents?clientId=" + clientId + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }
    
    initiateDocuments() {
        return getRequest({
            url: DOCUMENTS + "/initiate/documents",
            method: 'GET'
        });
    }

    approveDocument(documentJson) {
        return postRequest({
            url: DOCUMENTS + "/approval",
            method: 'POST',
            body: JSON.stringify(documentJson)
        }); 
    }

    // Students
    addStudent(studentJson) {
        return postRequest({
            url: STUDENT + "/create/student",
            method: 'POST',
            body: JSON.stringify(studentJson)
        });
    }
    addStudentBatch(excelFile, gradeId) {
        const formData = new FormData();
        formData.append('file', excelFile);
        formData.append('gradeId', gradeId);
        return postFiles({
            url: STUDENT + "/add/student/batch",
            method: 'POST',
            body: formData
        });
    }
    upgradeLevel(upgradeJson) {
        return postRequest({
            url: STUDENT + "/upgrade/level",
            method: 'POST',
            body: JSON.stringify(upgradeJson)
        });
    }
    getStudentInfo(accountId) {
        return getRequest({
            url: STUDENT + "/info?accountId=" + accountId,
            method: 'GET'
        });
    }
    getStudents(firstName, middleName, lastName, phoneNumber, regNumber, accountId, Status, page, limit, sort, order) {
        return getRequest({
            url: STUDENT + "/list?firstName=" + firstName + "&middleName=" + middleName + "&lastName=" + lastName + "&phoneNumber=" + phoneNumber + "&regNumber=" + regNumber + "&accountId=" + accountId + "&Status=" + Status + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    // Sms Notifications
    getSmsPaymentReference() {
        return getRequest({
            url: SMS_NOTIFICATION + "/payment/reference",
            method: 'GET'
        });
    }

    getSmsPaymentHistory(page, limit, sort, order) {
        return getRequest({
            url: SMS_NOTIFICATION + "/payment/history?page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    getSmsScope() {
        return getRequest({
            url: SMS_NOTIFICATION + "/get/sms/scope",
            method: 'GET'
        });
    }

    sendServiceSms(smsJson) {
        return postRequest({
            url: SMS_NOTIFICATION + "/service/sms",
            method: 'POST',
            body: JSON.stringify(smsJson)
        }); 
    }

    sendGroupSms(smsJson) {
        return postRequest({
            url: SMS_NOTIFICATION + "/group/sms",
            method: 'POST',
            body: JSON.stringify(smsJson)
        }); 
    }

    sendAccountInfoSms(smsJson) {
        return postRequest({
            url: SMS_NOTIFICATION + "/account/info/sms",
            method: 'POST',
            body: JSON.stringify(smsJson)
        }); 
    }
}