import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { WalletService } from '../../service/WalletService';
import { LgaService } from '../../service/LgaService';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import {ProgressBar} from 'primereact/progressbar';
import BeatLoader from "react-spinners/BeatLoader";
import { format } from 'date-fns';
import { withTranslation } from "react-i18next";

class HouseHolders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            displayConfirmation: false,
            deleteDataName: 0,
            deleteDataId: 0,
            dataTableValue:[],
            zones: [],
            cells: [],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            zoneId: 0,
            cellId: 0,
            name: '',
            houseNumber: '',
            phoneNumber: '',
            reference: '',
            status: '',
            state: '',
            tenantType: '',
            search: '',
            sortOrder: 1,
            sortField: 'houseNumber',
            month: '',
            displayModal: false,
            updating: false,
            loading: false
        };

        this.walletService = new WalletService();
        this.lgaService = new LgaService();
        this.adalipaUtil = new AdalipaUtil();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.updateHouseHoldersData = this.updateHouseHoldersData.bind(this);
        this.removeData = this.removeData.bind(this);
        this.onDeleteButtonClick = this.onDeleteButtonClick.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.booleanTemplate = this.booleanTemplate.bind(this);
        this.barTemplate = this.barTemplate.bind(this);
        this.cellTemplate = this.cellTemplate.bind(this);
        this.collectedIncomeTemplate = this.collectedIncomeTemplate.bind(this);
        this.export = this.export.bind(this);

        this.cols = [
            { field: 'name', header: this.props.t('Full Name') },
            { field: 'houseNumber', header: this.props.t('House Number') },
            { field: 'phoneNumber', header: this.props.t('Phone Number') },
            { field: 'reference', header: this.props.t('Reference') },
            { field: 'state', header: this.props.t('Malipo') },
            { field: 'cellName', header: this.props.t('Cell Number') },
            { field: 'zoneName', header: this.props.t('Zone') },
            { field: 'tenants', header: this.props.t('Number of Tenants') },
            { field: 'tenantType', header: this.props.t('Tenant Types') },
        ];
        this.excelCols = {header: ['name', 'houseNumber', 'phoneNumber', 'reference', 'status', 'cellName', 'zoneName', 'tenants', 'tenantType']};
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }

    getDataList = () => {
        this.setState({loading: true});
        return this.lgaService.getHouseHolders({zoneId: this.state.zoneId, cellId: this.state.cellId, name: this.state.name, houseNumber: this.state.houseNumber, phoneNumber: this.state.phoneNumber, reference: this.state.reference, status: this.state.status, state: this.state.state, tenantType: this.state.tenantType, search: this.state.search, page: this.state.pageIndex, limit: this.state.pageSize, sort: this.state.sortField, order: this.state.sortOrder === 1 ? 'asc' : 'desc'})
        .then(data => {
            this.setState({loading: false});
            const dataContent = data.content.map(d => ({
                ...d,
                cellName: d.cell.cellNumber + " - " + d.cell.zone.name,
                zoneName: d.zone.name
            }))
            this.setState({dataTableValue: dataContent});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
    }

    getCells = () => {
        const jsonParams = { zoneId: this.state.zoneId, page: 0, limit: 100, sort: "id", order: 'asc' }
        this.lgaService.getCells(jsonParams)
        .then(data => {
            const dataContent = data.content;
            const defaultOptions = [{"id": 0, "name": this.props.t("All Cells")},];
            const options = dataContent.map(d => ({
                "id" : d.id,
                "name" : d.cellNumber + " - " + (d.zone.name)
            }));
            this.setState({cells: defaultOptions.concat(options)});
        });
    }

    componentDidMount() {
        this.getDataList();
        const jsonParams = { page: 0, limit: 100, sort: "id", order: 'asc' }
        this.lgaService.getZones(jsonParams)
        .then(data => {
            const dataContent = data.content;
            const defaultOptions = [{"id": 0, "name": this.props.t("All Zones")}];
            const options = dataContent.map(d => ({
                "id" : d.id,
                "name" : d.name
            }));
            this.setState({zones: defaultOptions.concat(options)});
        });
        this.getCells();
    }

    onPageChange(event) {
        this.setState({pageIndex: event.page, pageSize: event.rows}, () => {
            this.getDataList().then(res => {
                this.setState({first: event.first, pageSize: event.rows});
            })
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getDataList();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });   
    }

    handleDropdownChange(event) {
        const target = event;
        const inputName = target.target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            if(inputName === "zoneId") {
                this.getCells();
                this.setState({cellId: 0}, () => {this.getDataList();});
            } else {
                this.getDataList();
            }
        });
    }

    removeData() {
        this.lgaService.removeHouseHolder(this.state.deleteDataId, "DELETE")
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.setState({displayConfirmation: false});
            this.getDataList();
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
            this.setState({displayConfirmation: false});
        });
    }
    onDeleteButtonClick = (data) => {
        this.setState({deleteDataName: data.name});
        this.setState({deleteDataId: data.id});
        this.setState({displayConfirmation: true});
    }

    updateHouseHoldersData(e) {
        e.preventDefault();
        this.setState({updating: true});
        this.lgaService.getHouseHoldersMonthBills(this.state.month)
        .then(response => {
            this.setState({displayModal: false});
            this.setState({updating: false});
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.getDataList(this.state.houseHolderId);
        }).catch(error => {
            this.setState({displayModal: false});
            this.setState({updating: false});
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    booleanTemplate(rowData, column) {
        return <span className={`p-button ${rowData['status'] === 'Imelipwa' ? 'p-button-success' : 'p-button-danger'}`} style={{display: 'block', textAlign: "center", fontSize: "12px"}}>{this.props.t(rowData['status'])}</span>;
    }
    barTemplate(rowData, column) {
        return <ProgressBar value={rowData['state'].replace("%","")} showValue={true} unit="%" className="p-bg-success p-ml-0 p-pl-0" />;
    }
    cellTemplate(rowData, column) {
        return <span>{rowData['cell'].cellNumber} - {rowData['cell'].zone.name}</span>;
    }
    collectedIncomeTemplate(rowData, column) {
        return this.adalipaUtil.amountFormat(rowData['collectedIncome']);
    }

    actionTemplate(rowData, column) {
        return <div style={{whiteSpace: "nowrap"}}>
            <Link to={"/wallet/lga/month/bills/" + rowData["id"]}><Button type="button" icon="pi pi-eye" className="p-button p-button-sm p-button-success" style={{marginRight: '.2em', fontSize: "10px"}} title={this.props.t("View Month Bills")}/></Link>
            <Link to={"/wallet/lga/house/holder/" + rowData["id"]}><Button type="button" icon="pi pi-pencil" className="p-button p-button-sm p-button-warning" style={{marginRight: '.2em', fontSize: "10px"}} title={this.props.t("Edit House Holder")}/></Link>
            <Button type="button"onClick={() => this.onDeleteButtonClick(rowData)} icon="pi pi-trash" className="p-button p-button-sm p-button-danger" style={{fontSize: "10px"}}/>
        </div>;
    }

    export() {
        this.dt.exportCSV();
    }

    render() {

        let dialogFooter = <div className="p-grid">
            <div className="p-col-4 p-offset-2">
                <Button type="submit" label={this.props.t("Generate")} onClick={(e) => this.updateHouseHoldersData(e)} icon="pi pi-check-circle" className="p-button-sm" />
            </div>
            <div className="p-col-4">
                <Button type="button" label={this.props.t("Cancel")} onClick={() => this.setState({displayModal: false})} icon="pi pi-times-circle" className="p-button-sm" style={{backgroundColor: "#90009E"}} />
            </div>
        </div>;

        let confirmationDialogFooter = (
            <>
                <Button type="button" label="No" icon="pi pi-times" onClick={() => this.setState({displayConfirmation: false})} className="p-button-text p-button-success" />
                <Button type="button" label="Yes" icon="pi pi-check" onClick={() => this.removeData()} className="p-button-text p-button-danger" autoFocus />
            </>
        );
        let header = (
        <div style={{textAlign:'left'}}>
            <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
            <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.tableExcel(this.props.t("House Holders"), this.excelCols, this.state.dataTableValue)} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
            <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.printTable(this.props.t("List of House Holders"), this.exportColumns, this.state.dataTableValue)} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
        </div>
        );

        let nameFilter = <InputText type="text" name="name" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.name} onChange={this.handleInputChange}/>
        let houseNumberFilter = <InputText type="text" name="houseNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.houseNumber} onChange={this.handleInputChange}/>
        let phoneNumberFilter = <InputText type="text" name="phoneNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.phoneNumber} onChange={this.handleInputChange}/>
        let referenceFilter = <InputText type="text" name="reference" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.reference} onChange={this.handleInputChange}/>
        // let statusFilter = <InputText type="text" name="status" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.status} onChange={this.handleInputChange}/>
        let stateFilter = <InputText type="text" name="state" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.state} onChange={this.handleInputChange}/>
        let cellFilter = <Dropdown name="cellId" style={{width: '100%'}} placeholder={this.props.t("Choose Cell")} filterPlaceholder={this.props.t("Search") + " " + this.props.t("Cell")} options={this.state.cells} value={this.state.cellId} onChange={this.handleDropdownChange} optionLabel="name" optionValue="id" filter filterBy="name" autoWidth={false} />
        let zoneFilter = <Dropdown name="zoneId" style={{width: '100%'}} placeholder={this.props.t("Choose Zone")} filterPlaceholder={this.props.t("Search") + " " + this.props.t("Zone")} options={this.state.zones} value={this.state.zoneId} onChange={this.handleDropdownChange} optionLabel="name" optionValue="id" filter filterBy="name" autoWidth={false} />

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-4"><h2>{this.props.t('List of House Holders')}</h2></div>
                            <div className="p-col-12 p-md-8">
                                <Link to="#" style={{float: "right"}}>
                                    <Button onClick={() => this.setState({displayModal: true})} label={this.props.t(this.state.updating ? <BeatLoader color={"#90009E"} loading={this.state.loading} css={""} size={10} /> : "Update Months Bills")} icon="pi pi-cog"/>
                                </Link>
                                <Link to="/wallet/lga/house/holder/0" style={{float: "right"}}><Button label={this.props.t("Add House Holder")} icon="pi pi-plus"/></Link>
                                <Link to="/wallet/lga/house/holders/batch" style={{float: "right"}}><Button label={this.props.t("Add House Holders Using Excel")} icon="pi pi-plus"/></Link>
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header={header} paginator={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}
                            currentPageReportTemplate={this.props.t("Data") + " " + ((this.state.pageIndex * this.state.pageSize) + 1) + " " + this.props.t("to") + " " + ((this.state.pageIndex * this.state.pageSize) + this.state.numberOfElements) + " " + this.props.t("of") + " " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30,50,100,[this.state.totalElements]]} onPage={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" lazy={true}>
                            <Column field="name" header={this.props.t("Full Name")} sortable={true} filter={true} filterElement={nameFilter} style={{overflow: "auto"}}/>
                            <Column field="houseNumber" header={this.props.t("House Number")} sortable={true} sortField='houseNumber' filter={true} filterElement={houseNumberFilter} style={{width: "8%", overflow: "auto"}}/>
                            <Column field="phoneNumber" header={this.props.t("Phone Number")} sortable={true} sortField='phoneNumber' filter={true} filterElement={phoneNumberFilter} style={{width: "10%", overflow: "auto"}}/>
                            <Column field="reference" header={this.props.t("Reference")} sortable={true} sortField='reference' filterElement={referenceFilter} filter={true} style={{width: "12%", overflow: "auto"}}/>
                            <Column field="state" header={this.props.t("Payments")} body={this.barTemplate} sortable={true} sortField='status' filterElement={stateFilter} filter={true} style={{width: "8%", overflow: "auto"}}/>
                            <Column field="zoneName" header={this.props.t("Zone")} sortable={true} filter={true} sortField='zone' filterElement={zoneFilter} style={{width: "12%", overflow: "auto"}}/>
                            <Column field="cellName" header={this.props.t("Cell")} sortable={true} filter={true} sortField='cell' filterElement={cellFilter} style={{width: "12%", overflow: "auto"}}/>
                            <Column field="tenants" header={this.props.t("Number of Tenants")} sortable={true} filter={false} style={{width: "6%", overflow: "auto"}}/>
                            <Column field="frames" header={this.props.t("Number of Frames")} sortable={true} filter={false} style={{width: "6%", overflow: "auto"}}/>
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', fontSize: 'small', width: '7em'}}/>
                        </DataTable>
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#90009E"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }

                        <Dialog header={this.props.t("Update Month Bills")} visible={this.state.displayModal} className="p-col-4 p-offset-2" modal footer={dialogFooter} onHide={() => this.setState({displayModal: false})}>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-12">
                                    <label htmlFor="name">{this.props.t('Month')}</label>
                                    <Calendar name="month" placeholder={this.props.t("Choose") + " " + this.props.t("Month")} value={this.state.month} onChange={(e) => this.setState({month: format(e.value, 'yyyy-MM')})} view='month' dateFormat='MM, yy' yearNavigator={true} yearRange='2021:2030' appendTo={document.body}/>
                                </div>
                            </div>
                        </Dialog>
                        <Dialog header={this.props.t("Confirmation")} visible={this.state.displayConfirmation} onHide={() => this.setState({displayConfirmation: false})} style={{ width: '550px' }} modal footer={confirmationDialogFooter}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle" style={{ fontSize: '1.5rem', marginRight: "1rem", verticalAlign: "top", color: "#90009E" }}></i>
                                <span style={{ fontSize: 'normal', verticalAlign: "top" }}>{this.props.t('Are you sure you want to delete')} {this.state.deleteDataName}?</span>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(HouseHolders)
