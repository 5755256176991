import React, {Component} from 'react';
import { UserService } from '../service/UserService';
import {DataTable} from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";

class ListStaffs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue:[],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            sortOrder: -1,
            sortField: 'id',
            fullName: '',
            email: '',
            phoneNumber: '',
            loading: false
        };

        this.userService = new UserService();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
    }

    getDataList = () => {
        let {fullName, email, phoneNumber, pageIndex, pageSize, sortField, sortOrder} = this.state;
        this.setState({loading: true});
        this.userService.getStaffs({fullName: fullName, email: email, phoneNumber: phoneNumber, page: pageIndex, limit: pageSize, sort: sortField, order: sortOrder === 1 ? 'asc' : 'desc'})
        .then(data => {
            this.setState({loading: false});
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
    }

    componentDidMount() {
        this.getDataList();
    }

    onPageChange(event) {
        this.setState({pageIndex: event.page, pageSize: event.rows}, () => {
            this.getDataList().then(res => {
                this.setState({first: event.first, pageSize: event.rows});
            })
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getDataList();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });       
    }

    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" icon="pi pi-eye" title={this.props.t("View Staff")} className="p-button-info" style={{marginRight: '.5em'}}/>
        </div>;
    }

    render() {

        let fullNameFilter = <InputText type="text" name="fullName" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.fullName} onChange={this.handleInputChange}/>
        let emailFilter = <InputText type="text" name="email" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.email} onChange={this.handleInputChange}/>
        let phoneNumberFilter = <InputText type="text" name="phoneNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.phoneNumber} onChange={this.handleInputChange}/>

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-8 p-md-8"><h2>{this.props.t('List of Staffs')}</h2></div>
                            <div className="p-col-4 p-md-4"><Link to="/user/add/staff" style={{float: "right"}}><Button label={this.props.t("Add Staff")}/></Link></div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" paginator={false} 
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="fullName" header={this.props.t("Name")} sortable={true} filter={true} filterElement={fullNameFilter} filterPlaceholder="Contains"/>
                            <Column field="email" header={this.props.t("Email Address")} sortable={true} filter={true} filterElement={emailFilter} filterPlaceholder="Contains"/>
                            <Column field="phoneNumber" header={this.props.t("Phone Number")} sortable={true} filter={true} filterElement={phoneNumberFilter} filterPlaceholder="Contains"/>
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        <Paginator currentPageReportTemplate={"Data " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.pageSize) + " of " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                        </Paginator>
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#90009E"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(ListStaffs)