import 'jspdf-autotable';
import { format } from "date-fns";

export class AdalipaUtil {

    printTable = (title, dataColumns, dataList) => {
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                // var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
                var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
                const xOffset = (pageWidth / 2) - (doc.getStringUnitWidth(title) * doc.internal.getFontSize() / 5);
                doc.text(xOffset, 15, title);
                doc.autoTable(dataColumns, dataList, {
                    startY: 20,
                    headStyles: {
                        fillColor: '#3B007B',
                        lineWidth: 0.5,
                        lineColor: '#3B007B',
                        fontSize : 8
                    },
                    bodyStyles: {
                        lineWidth: 0.1,
                        lineColor: '#3B007B',
                        fontSize : 7
                    },
                    showHead: 'firstPage',
                    autoSize : true
                });
                doc.setFont("helvetica");
                doc.setFontSize(2);
                doc.autoPrint();
                doc.output('dataurlnewwindow');
            })
        })
    }

    tableExcel = (fileName, dataColumns, dataList) => {
        import('xlsx').then(xlsx => {
            const excelHeaders = dataColumns.header.length;
            const wscols = dataColumns.header.map(d => ({
                wch: d.length + 5
            }));
            const worksheet = xlsx.utils.json_to_sheet(dataList, dataColumns);

            const range = xlsx.utils.decode_range(worksheet['!ref']);
            range.e['c'] = +excelHeaders - 1;
            worksheet['!ref'] = xlsx.utils.encode_range(range);
            worksheet['!cols'] = wscols;

            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            xlsx.utils.book_append_sheet(workbook, worksheet, 'tab1');
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, fileName);
        });
    }
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    printReceipt = (htmlElement) => {
        import('jspdf').then(jsPDF => {
            const doc = new jsPDF.default(0, 0);
            doc.html(htmlElement).then(() => {
                doc.autoPrint();
                doc.output('dataurlnewwindow');
            })
        })
    }

    monthFormat = date => {
        return date ?
            format(new Date(Date.parse(date)), "MMMM, yyyy") :
            "No Yet"
    }

    dateFormat = date => {
        return date ?
            format(new Date(Date.parse(date)), "dd/MM/yyyy") :
            "No Yet"
    }

    dateTimeFormat = date => {
        return date ?
            format(new Date(Date.parse(date)), "dd/MM/yyyy HH:ii") :
            "No Yet"
    }
    
    normalTimeStampFormat = date => {
        return new Intl.DateTimeFormat('sw', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.parse(date));
    }

    timestampDateFormat = date => {
        return date ? format(new Date(Date.parse(date)), "yyyy-MM-dd") : "No Yet"
    }

    cleanDateFormat = date => {
        return date ? format(new Date(Date.parse(date)), "yyyy-MM-dd") : ""
    }

    timestampFormat = date => {
        return date ?
            format(new Date(Date.parse(date)), "yyyy-MM-dd HH:mm:ss") :
            "No Yet"
    }

    amountFormat = (amount) => {
        var formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'TZS',});
        return formatter.format(amount);
    }

    toDecimal = (num,div=",") => {
        // console.log(num);
    	const number = num?.toString().replace("NaN", 0).replace(div, "").replace("undefined", "");
    	return num > 0 ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, div) : num;
    }

    // phoneInputFormat = (phone) => {
    //     return <NumberFormat thousandSeparator={false} prefix={'+'} value={phone} />
    // }

    // phoneNumberFormat = (phone) => {
    //     return <NumberFormat value={phone} displayType={'text'} thousandSeparator={false} prefix={'+'} />
    // }

    // amountFormat = (amount) => {
    //     return <NumberFormat value={amount} displayType={'text'} thousandSeparator={true} prefix={'TZS '} />
    // }

    numberOfMessages = (messageStr) => {
        let messages = 1;
        if (messageStr.length > 160) {
            messages = Math.ceil(messageStr.length / 153);
        }
        return {messages: messages, messageLenth: messageStr.length};
    }
}
