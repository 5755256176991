import { REAL_ESTATE } from '../api';
import { postRequest, getRequest, queryParams } from './CoreService';

export class RealEstateService {

    addProject(dataJson) {
        return postRequest({
            url: REAL_ESTATE + "/project",
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    editProject(dataJson) {
        return postRequest({
            url: REAL_ESTATE + "/project",
            method: "PUT",
            body: JSON.stringify(dataJson)
        });
    }

    getProject(dataId) {
        return postRequest({
            url: REAL_ESTATE + "/project/" + dataId,
            method: "GET"
        });
    }

    removeProject(dataId) {
        return postRequest({
            url: REAL_ESTATE + "/project/" + dataId,
            method: "DELETE"
        });
    }

    getProjects(jsonParams) {
        return getRequest({
            url: REAL_ESTATE + "/project" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    addCustomer(dataJson) {
        return postRequest({
            url: REAL_ESTATE + "/customer",
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    editCustomer(dataJson) {
        return postRequest({
            url: REAL_ESTATE + "/customer",
            method: "PUT",
            body: JSON.stringify(dataJson)
        });
    }

    getCustomer(dataId) {
        return postRequest({
            url: REAL_ESTATE + "/customer/" + dataId,
            method: "GET"
        });
    }

    removeCustomer(dataId) {
        return postRequest({
            url: REAL_ESTATE + "/customer/" + dataId,
            method: "DELETE"
        });
    }

    getCustomers(jsonParams) {
        return getRequest({
            url: REAL_ESTATE + "/customer" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    addCustomerProject(dataJson) {
        return postRequest({
            url: REAL_ESTATE + "/customer/project",
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    editCustomerProject(dataJson) {
        return postRequest({
            url: REAL_ESTATE + "/customer/project",
            method: "PUT",
            body: JSON.stringify(dataJson)
        });
    }

    getCustomerProject(dataId) {
        return postRequest({
            url: REAL_ESTATE + "/customer/project/" + dataId,
            method: "GET"
        });
    }

    removeCustomerProject(dataId) {
        return postRequest({
            url: REAL_ESTATE + "/customer/project/" + dataId,
            method: "DELETE"
        });
    }

    getCustomerProjects(jsonParams) {
        return getRequest({
            url: REAL_ESTATE + "/customer/project" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getCustomerFee(feeId) {
        return getRequest({
            url: REAL_ESTATE + "/customer/fee/" + feeId,
            method: 'GET'
        });
    }

    getCustomerFees(jsonParams) {
        return getRequest({
            url: REAL_ESTATE + "/customer/fee" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    addInstallment(dataJson) {
        return postRequest({
            url: REAL_ESTATE + "/installment",
            method: "POST",
            body: JSON.stringify(dataJson)
        });
    }

    getInstallment(dataId) {
        return postRequest({
            url: REAL_ESTATE + "/installment/" + dataId,
            method: "GET"
        });
    }

    getInstallments(dataId) {
        return postRequest({
            url: REAL_ESTATE + "/installments/" + dataId,
            method: "GET"
        });
    }
}