import { CONTRIBUTION, CONTRIBUTION_GROUP, CONTRIBUTION_MEMBER, OTHER_BUSINESS, OTHER_BUSINESS_BUYER } from '../api';
import { API_BASE_URL } from '../constants';
import { postRequest, getRequest, queryParams, postFiles } from './CoreService';

export class WalletService {

    addCollection(collectionJson) {
        return postRequest({
            url: API_BASE_URL + "/collection/add/collection",
            method: 'POST',
            body: JSON.stringify(collectionJson)
        });
    }
    fundsTransfer(fundsJson) {
        return postRequest({
            url: API_BASE_URL + "/collection/funds/transfer",
            method: 'POST',
            body: JSON.stringify(fundsJson)
        });
    }
    getCollections(walletName, walletCode, walletAccount, page, limit, sort, order) {
        return getRequest({
            url: API_BASE_URL + "/collection/get/collections?walletName=" + walletName + "&walletCode=" + walletCode + "&walletAccount=" + walletAccount + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }
    getCollectionBalances(account, page, limit, sort, order) {
        return getRequest({
            url: API_BASE_URL + "/collection/get/collection/balance?account=" + account + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }
    getCollectionFees(mobileNumber, receipt, state, status, page, limit, sort, order) {
        return getRequest({
            url: API_BASE_URL + "/collection/get/collection/fees?mobileNumber=" + mobileNumber + "&receipt=" + receipt + "&state=" + state + "&status=" + status + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    getTransactions(jsonParams) {
        return getRequest({
            url: API_BASE_URL + "/fee/transactions" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    getTransaction(dataId) {
        return getRequest({
            url: API_BASE_URL + "/fee/transactions/" + dataId,
            method: 'GET'
        });
    }

    // Balance Api
    createAccount(balanceJson) {
        return postRequest({ url: API_BASE_URL + "/balance/create/account", method: 'POST', body: JSON.stringify(balanceJson) });
    }

    updateAccount(balanceJson) {
        return postRequest({ url: API_BASE_URL + "/balance/withdrawal/account", method: 'PUT', body: JSON.stringify(balanceJson) });
    }

    getWithdrawalAccount() {
        return getRequest({ url: API_BASE_URL + "/balance/withdrawal/account", method: 'GET' });
    }

    createOtherAccount(balanceJson) {
        return postRequest({ url: API_BASE_URL + "/balance/other/withdrawal/account", method: 'POST', body: JSON.stringify(balanceJson) });
    }

    updateOtherAccount(balanceJson) {
        return postRequest({ url: API_BASE_URL + "/balance/other/withdrawal/account", method: 'PUT', body: JSON.stringify(balanceJson) });
    }

    getOtherWithdrawalAccount() {
        return getRequest({ url: API_BASE_URL + "/balance/other/withdrawal/account", method: 'GET' });
    }

    createSettlement(settlementJson) {
        return postRequest({
            url: API_BASE_URL + "/balance/create/settlement",
            method: 'POST',
            body: JSON.stringify(settlementJson)
        });
    }

    getSettlements(transactionId, settledFrom, accountNumber, mlipaResponse, responseComment, page, limit, sort, order) {
        return getRequest({
            url: API_BASE_URL + "/balance/settlements?transactionId=" + transactionId + "&settledFrom=" + settledFrom + "&accountNumber=" + accountNumber + "&mlipaResponse=" + mlipaResponse + "&responseComment=" + responseComment + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    // Contributions
    group(groupJson, method) {
        return postRequest({
            url: CONTRIBUTION_GROUP,
            method: method,
            body: JSON.stringify(groupJson)
        });
    }
    groupInfo(groupId, method) {
        return postRequest({
            url: CONTRIBUTION_GROUP + "?groupId=" + groupId,
            method: method
        });
    }
    getGroups(jsonParams) {
        return getRequest({
            url: CONTRIBUTION + "/groups" + queryParams(jsonParams),
            method: 'GET'
        });
    }

    member(memberJson, method) {
        return postRequest({
            url: CONTRIBUTION_MEMBER,
            method: method,
            body: JSON.stringify(memberJson)
        });
    }
    addMemberBatch(excelFile, groupId, sendSms) {
        const formData = new FormData();
        formData.append('file', excelFile);
        formData.append('groupId', groupId);
        formData.append('sendSms', sendSms);
        return postFiles({
            url: CONTRIBUTION_MEMBER + "/batch",
            method: 'POST',
            body: formData
        });
    }
    memberInfo(memberId, method) {
        return postRequest({
            url: CONTRIBUTION_MEMBER + "?memberId=" + memberId,
            method: method
        });
    }
    getMembers(groupId, memberName, phoneNumber, memberType, page, limit, sort, order) {
        return getRequest({
            url: CONTRIBUTION + "/members?groupId=" + groupId + "&memberName=" + memberName + "&phoneNumber=" + phoneNumber + "&memberType=" + memberType + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    getContributions(groupId, memberId, memberCode, paymentId, page, limit, sort, order) {
        return getRequest({
            url: CONTRIBUTION + "/list?groupId=" + groupId + "&memberId=" + memberId + "&memberCode=" + memberCode + "&paymentId=" + paymentId + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }

    // Other Businesses
    business(buyerJson, method) {
        return postRequest({
            url: OTHER_BUSINESS,
            method: method,
            body: JSON.stringify(buyerJson)
        });
    }
    businessInfo(customerId, method) {
        return postRequest({
            url: OTHER_BUSINESS + "/" + customerId,
            method: method
        });
    }
    getBusinesses(business, description, page, limit, sort, order) {
        return getRequest({
            url: OTHER_BUSINESS + "?business=" + business + "&description=" + description + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }
    buyer(buyerJson, method) {
        return postRequest({
            url: OTHER_BUSINESS_BUYER,
            method: method,
            body: JSON.stringify(buyerJson)
        });
    }
    buyerInfo(customerId, method) {
        return postRequest({
            url: OTHER_BUSINESS_BUYER + "?customerId=" + customerId,
            method: method
        });
    }
    getCustomers(businessId, customerName, phoneNumber, paymentId, page, limit, sort, order) {
        return getRequest({
            url: OTHER_BUSINESS + "/customers?businessId=" + businessId + "&customerName=" + customerName + "&phoneNumber=" + phoneNumber + "&paymentId=" + paymentId + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }
    getArchivedCustomers(businessId, customerName, phoneNumber, paymentId, page, limit, sort, order) {
        return getRequest({
            url: OTHER_BUSINESS + "/customers?businessId=" + businessId + "&customerName=" + customerName + "&phoneNumber=" + phoneNumber + "&paymentId=" + paymentId + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order + "&archived=true",
            method: 'GET'
        });
    }
    getCustomerTransactions(businessId, accountId, phoneNumber, receiptNumber, client, customer, reqTimeFrom, reqTimeTo, page, limit, sort, order) {
        return getRequest({
            url: OTHER_BUSINESS + "/transactions?businessId=" + businessId + "&accountId=" + accountId + "&phoneNumber=" + phoneNumber + "&receiptNumber=" + receiptNumber + "&client=" + client + "&customer=" + customer + "&reqTimeFrom=" + reqTimeFrom + "&reqTimeTo=" + reqTimeTo + "&page=" + page + "&limit=" + limit + "&sort=" + sort + "&order=" + order,
            method: 'GET'
        });
    }
}