import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { WalletService } from '../../service/WalletService';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { withTranslation } from "react-i18next";

class UpdateOtherAccount extends Component {

	constructor(props) {
        super(props);
        this.state = {
            accountId: 0,
            accountName: '',
            accountNumber: '',
            accountHolder: '',
            accountType: 'Bank',
            alert: '',
            failed: null
        };
        this.walletService = new WalletService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        this.walletService.getOtherWithdrawalAccount()
        .then(response => {
            this.setState({
                accountId: response.id,
                accountName: response.accountName,
                accountNumber: response.accountNumber,
                accountHolder: response.accountHolder,
                accountType: response.accountType
            });
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();
        const accountJson = {
            accountId: this.state.accountId,
            accountName: this.state.accountName,
            accountNumber: this.state.accountNumber,
            accountHolder: this.state.accountHolder,
            accountType: this.state.accountType
        };
        this.walletService.updateOtherAccount(accountJson)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/update/other/account");
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/update/account",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        { this.state.failed === true &&
                            <div className="p-messages p-component p-messages-warn" style={{margin: '0 0 1em 0', display: 'block'}}>
                                    <div className="p-messages-wrapper rounded">
                                        <ul>
                                            <li>
                                                <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                                <span className="p-messages-detail">{this.state.alert}</span>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        }
                        { this.state.failed === false &&
                            <div className="p-messages p-component p-messages-success" style={{margin: '0 0 1em 0', display: 'block'}}>
                                    <div className="p-messages-wrapper rounded">
                                        <ul>
                                            <li>
                                                <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                                <span className="p-messages-detail">{this.state.alert}</span>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        }

                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#90009E", color: "#FFFFFF", padding: "1%"}}>
                                <h2 style={{textAlign: "center"}}>{this.props.t('Update Other Collections Withdrawal Account')}</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Account Name')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="accountName" placeholder={this.props.t("Enter") + " " + this.props.t("Account Name")} value={this.state.accountName} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Account Number')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="accountNumber" placeholder={this.props.t("Enter") + " " + this.props.t("Account Number")} value={this.state.accountNumber} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Account Holder')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="accountHolder" placeholder="eg. Bank of Tanzania" value={this.state.accountHolder} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">{this.props.t('Account Type')}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="accountType" placeholder="eg. Bank" value={this.state.accountType} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label={this.props.t("Update Account")} icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}
export default withTranslation()(UpdateOtherAccount)