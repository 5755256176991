import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { WalletService } from '../service/WalletService';
import { Paginator } from 'primereact/paginator';
import { AdalipaUtil } from '../Utilities/AdalipaUtil';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";

class CollectionBalance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue:[],
            excelData:[],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            account: '',
            Status: null,
            sortOrder: -1,
            sortField: 'id',
            loading: false
        };

        this.walletService = new WalletService();
        this.adalipaUtil = new AdalipaUtil();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.export = this.export.bind(this);

        this.cols = [
            { field: 'walletName', header: 'Collection' },
            { field: 'account', header: 'Payment Reference' },
            { field: 'currentAmount', header: 'Current Amount' },
            { field: 'settledAmount', header: 'Settled Amount' },
            { field: 'actualAmount', header: 'Actual Amount' }
        ];
        this.excelCols = {header: ['walletName', 'account', 'currentAmount', 'settledAmount', 'actualAmount']};
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }

    componentDidMount() {
        this.setState({loading: true});
        this.walletService.getCollectionBalances(this.state.account, 0, this.state.pageSize, 'id', 'asc')
        .then(data => {
            this.setState({loading: false});
            const dataContent = data.content.map(d => ({
                ...d, walletName: d.collection.walletName
            }));
            this.setState({dataTableValue: dataContent});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
            const excelOptions = data.content.map(d => ({
                walletName: d.collection.walletName, account: d.account, currentAmount: d.currentAmount, settledAmount: d.settledAmount, actualAmount: d.actualAmount
            }));
            this.setState({excelData: excelOptions});
        });
    }

    onPageChange(event) {
        this.setState({loading: true});
        this.walletService.getCollectionBalances(this.state.account, event.page, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
        .then(data => {
            this.setState({loading: false});
            const dataContent = data.content.map(d => ({
                ...d, walletName: d.collection.walletName
            }));
            this.setState({dataTableValue: dataContent});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
            this.setState({first: event.first, pageSize: event.rows});
            const excelOptions = data.content.map(d => ({
                walletName: d.collection.walletName, account: d.account, currentAmount: d.currentAmount, settledAmount: d.settledAmount, actualAmount: d.actualAmount
            }));
            this.setState({excelData: excelOptions});
        });
    }

    onSortChange(event) {
        this.setState({loading: true});
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.walletService.getCollectionBalances(this.state.account, 0, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({loading: false});
                const dataContent = data.content.map(d => ({
                    ...d, walletName: d.collection.walletName
                }));
                this.setState({dataTableValue: dataContent});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
                const excelOptions = data.content.map(d => ({
                    walletName: d.collection.walletName, account: d.account, currentAmount: d.currentAmount, settledAmount: d.settledAmount, actualAmount: d.actualAmount
                }));
                this.setState({excelData: excelOptions});
            });
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.setState({loading: true});
            this.walletService.getCollectionBalances(this.state.account, 0, this.state.pageSize, 'id', 'asc')
            .then(data => {
                this.setState({loading: false});
                const dataContent = data.content.map(d => ({
                    ...d, walletName: d.collection.walletName
                }));
                this.setState({dataTableValue: dataContent});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
                const excelOptions = data.content.map(d => ({
                    walletName: d.collection.walletName, account: d.account, currentAmount: d.currentAmount, settledAmount: d.settledAmount, actualAmount: d.actualAmount
                }));
                this.setState({excelData: excelOptions});
            });
        });     
    }

    booleanTemplate(rowData, column) {
        return rowData['enabled'] === true ? 'Yes' : 'No';
    }

    actionTemplate(rowData, column) {
        return <div>
            <Link to={"/wallet/funds/transfer/" + rowData["collection"].id}><Button type="button" icon="pi pi-money-bill" className="p-button-warning"/></Link>
        </div>;
    }

    export() {
        this.dt.exportCSV();
    }

    render() {
        let header = <div style={{textAlign:'left'}}>
            <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
            <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.tableExcel("CollectionBalances", this.excelCols, this.state.dataTableValue)} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
            <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.printTable("Collection Balances", this.exportColumns, this.state.dataTableValue)} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
        </div>
        let walletNameFilter = <InputText type="text" name="walletName" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.walletName} onChange={this.handleInputChange}/>
        let accountFilter = <InputText type="text" name="account" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.account} onChange={this.handleInputChange}/>

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>{this.props.t('Collection Balances')}</h2></div>
                            <div className="p-col-8 p-md-8">
                                <Link to="/wallet/add/collection" style={{float: "right"}}><Button label={this.props.t("Add Collection")} icon="pi pi-plus"/></Link>
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single"header={header} paginator={false} 
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="collection.walletName" header={this.props.t("Collection")} sortable={true} filter={true} filterElement={walletNameFilter} filterPlaceholder="Contains"/>
                            <Column field="account" header={this.props.t("Payment Reference")} sortable={true} filter={true} filterElement={accountFilter} filterPlaceholder="Contains"/>
                            <Column field="actualAmount" header={this.props.t("Actual Amount")} sortable={true} filter={false}/>
                            <Column field="settledAmount" header={this.props.t("Settled Amount")} sortable={true} filter={false}/>
                            <Column field="currentAmount" header={this.props.t("Current Amount")} sortable={true} filter={false}/>
                            <Column header={this.props.t("Actions")} body={this.actionTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        <Paginator currentPageReportTemplate={"Showing " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.pageSize) + " of " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                        </Paginator>
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#90009E"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(CollectionBalance)