import React, {Component} from 'react';
import { UserService } from '../service/UserService';
import {DataTable} from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import {Column} from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import BeatLoader from "react-spinners/BeatLoader";

export class ListUsers extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue:[],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            sortOrder: -1,
            sortField: 'id',
            fullName: '',
            email: '',
            phoneNumber: '',
            loading: false
        };

        this.userService = new UserService();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.setState({loading: true});
        this.userService.getUsers(this.state.fullName, this.state.email, this.state.phoneNumber, 0, this.state.pageSize, 'id', 'asc')
        .then(data => {
            this.setState({loading: false});
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
    }

    onPageChange(event) {
        this.setState({loading: true});
        this.userService.getUsers(this.state.fullName, this.state.email, this.state.phoneNumber, event.page, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
        .then(data => {
            this.setState({loading: false});
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
            this.setState({first: event.first, pageSize: event.rows});
        });
    }

    onSortChange(event) {
        this.setState({loading: true});
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.userService.getUsers(this.state.fullName, this.state.email, this.state.phoneNumber, 0, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({loading: false});
                this.setState({dataTableValue: data.content});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
            });
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.setState({loading: true});
            this.userService.getUsers(this.state.fullName, this.state.email, this.state.phoneNumber, 0, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({loading: false});
                this.setState({dataTableValue: data.content});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
            });
        });     
    }

    render() {

        let fullNameFilter = <InputText type="text" name="fullName" style={{width: '100%'}} placeholder="Contains" value={this.state.fullName} onChange={this.handleInputChange}/>
        let emailFilter = <InputText type="text" name="email" style={{width: '100%'}} placeholder="Contains" value={this.state.email} onChange={this.handleInputChange}/>
        let phoneNumberFilter = <InputText type="text" name="phoneNumber" style={{width: '100%'}} placeholder="Contains" value={this.state.phoneNumber} onChange={this.handleInputChange}/>

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-8 p-md-8"><h2>List of Registered Users</h2></div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" paginator={false} 
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="fullName" header="Name" sortable={true} filter={true} filterElement={fullNameFilter} filterPlaceholder="Contains"/>
                            <Column field="partner.partnerName" header="Partner" sortable={true} filter={false}/>
                            <Column field="institution.instituteName" header="Client" sortable={true} filter={false}/>
                            <Column field="email" header="Email Address" sortable={true} filter={true} filterElement={emailFilter} filterPlaceholder="Contains"/>
                            <Column field="phoneNumber" header="Phone Number" sortable={true} filter={true} filterElement={phoneNumberFilter} filterPlaceholder="Contains"/>
                            <Column field="region.regionName" header="City" sortable={true} filter={false}/>
                            <Column field="userType.userType" header="User Type" sortable={true} filter={false}/>
                        </DataTable>
                        <Paginator currentPageReportTemplate={"Showing " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.pageSize) + " of " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                        </Paginator>
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#90009E"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
