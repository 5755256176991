import { ACCESS_TOKEN } from '../constants';

export const loginRequest = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + btoa('data:admin@100%'),
    })

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response => 
        response.json().then(json => {
            if(!response.ok) {
                if(response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }
                return Promise.reject(json);
            }
            return json;
        })
    );
};

export const postForms = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
    })
    
    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response => 
        response.json().then(json => {
            if(!response.ok) {
                if(response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }
                return Promise.reject(json);
            }
            return json;
        })
    );
};

export const postFiles = (options) => {
    const headers = new Headers({
    })
    
    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response => 
        response.json().then(json => {
            if(!response.ok) {
                if(response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }
                return Promise.reject(json);
            }
            return json;
        })
    );
};

export const postRequest = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })
    
    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response => 
        response.json().then(json => {
            if(!response.ok) {
                if(response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }
                return Promise.reject(json);
            }
            return json;
        })
    );
};

export const getRequest = (options) => {
    const headers = new Headers({})
    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }
    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);
    return fetch(options.url, options)
    .then(response => 
        response.json().then(json => {
            if(!response.ok) {
                if(response.status === 401) {
                    localStorage.clear();
                    window.location.href = "/#/login";
                }
                return Promise.reject(json);
            }
            return json;
        })
    );
};

export const queryParams = params => {
    if (!params)
        return "";

    let out = Object.keys(params).map((value, key) => {
        if (key === 0)
            return `?${value}=${params[value]}`;
        else
            return `&${value}=${params[value]}`;
    })
    return out.join("");
}