import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { RealEstateService } from '../../service/RealEstateService';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import ResponseAlert from '../../Utilities/ResponseAlert';

export default class Project extends Component {

	constructor(props) {
        super(props);
        this.state = {
            dataJson: {projectName: '', description: 'N/A'},
            dataId: 0,
            failed: null,
            alert: ''
        };
        this.realEstateService = new RealEstateService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handelEdit = this.handelEdit.bind(this);
	}
	
	componentDidMount() {
        if (this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        if (+this.props.match.params.dataId > 0) {
            this.setState({dataId: +this.props.match.params.dataId});
            this.realEstateService.getProject(+this.props.match.params.dataId)
            .then(data => {
                this.setState({dataJson: {id: data.id, projectName: data.projectName, description: data.description}});
            });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState(prevJson => ({
            dataJson: {
                ...prevJson.dataJson, [inputName] : inputValue
            }
        }));        
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.realEstateService.addProject(this.state.dataJson)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/real/estate/projects");
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

    handelEdit = (event) => {
        event.preventDefault();
        this.realEstateService.editProject(this.state.dataJson)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/real/estate/projects");
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/real/estate/projects/0",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <ResponseAlert failed={this.state.failed} alert={this.state.alert} />
                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#90009E", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>{this.state.dataId === 0 ? "New" : "Edit"} Project</h2>
                            </div>
                            <form onSubmit={this.state.dataId === 0 ? this.handleSubmit : this.handelEdit}>
                                <div className="p-grid">
                                    <div className="p-md-offset-1 p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Project Name:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="projectName" placeholder="Enter project name" value={this.state.dataJson.projectName} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Description:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="description" placeholder="Enter details" value={this.state.dataJson.description} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label={(this.state.dataId === 0 ? "Add" : "Edit") + " Project"} icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}