import React, { Component } from 'react';
import { ClientService } from '../service/ClientService';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';

export class ApproveDocuments extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue:[],
            layout: 'list',
            sortOptions: [
                {label: 'Newest First', value: '!year'},
                {label: 'Oldest First', value: 'year'},
                {label: 'Brand', value: 'brand'}
            ],
            failed: null,
            alert: ''
        };

        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 3,
                numScroll: 3
            },
            {
                breakpoint: '768px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '560px',
                numVisible: 1,
                numScroll: 1
            }
        ];

        this.clientService = new ClientService();

        this.approveDocument = this.approveDocument.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
    }

    componentDidMount() {
        this.clientService.getClientDocuments(this.props.match.params.clientid, 0, 100, 'id', 'asc')
        .then(data => {
            this.setState({dataTableValue: data.content});
        });
    }

    approveDocument(docId, action) {
        const documentJson = {
            documentId: docId,
            status: action
        }
        this.clientService.approveDocument(documentJson)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.clientService.getClientDocuments(this.props.match.params.clientid, 0, 100, 'id', 'asc')
            .then(data => {
                this.setState({dataTableValue: data.content});
            });
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
    }

    onSortChange(event) {
        let value = event.value;

        if (value.indexOf('!') === 0)
            this.setState({sortOrder: -1, sortField:value.substring(1, value.length), sortKey: value});
        else
            this.setState({sortOrder: 1, sortField:value, sortKey: value});
    }

    actionTemplate(rowData, column) {
        return <div>
            {rowData['status'] === "Approved" && <Button type="button" onClick={() => this.approveDocument(rowData['id'], "Declined")} icon="pi pi-times" className="p-button-danger" title="Decline Document"/>}
            {rowData['status'] !== "Approved" && rowData['status'] !== "Unavailable" && <Button type="button" onClick={() => this.approveDocument(rowData['id'], "Approved")} icon="pi pi-check" className="p-button-success" title="Approve Document"/>}
            {rowData['status'] !== "Unavailable" && <Link to="#"><Button type="button" icon="pi pi-eye" className="p-button-adalipa" style={{marginRight: '.5em'}} title="View Document"/></Link>}
        </div>;
    }

    render() {

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-messages p-component p-messages-warning" style={{margin: '0 0 1em 0', display: 'block'}}>
                        { this.state.failed === true &&
                            <div className="p-messages-wrapper rounded">
                                <ul>
                                    <li>
                                        <span className="p-messages-icon pi pi-fw pi-2x pi-exclamation-triangle"></span>
                                        <span className="p-messages-detail">{this.state.alert}</span>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                    <div className="p-messages p-component p-messages-success" style={{margin: '0 0 1em 0', display: 'block'}}>
                        { this.state.failed === false &&
                            <div className="p-messages-wrapper rounded">
                                <ul>
                                    <li>
                                        <span className="p-messages-icon pi pi-fw pi-2x pi-check-circle"></span>
                                        <span className="p-messages-detail">{this.state.alert}</span>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-8 p-md-8"><h1>Documents</h1></div>
                            {/* <div className="p-col-4 p-md-4"><Link to="/fee/add-fee-type" style={{float: "right"}}><Button label="Add Fee Type"/></Link></div> */}
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" paginator={true} rows={10}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="documentType" header="Document" sortable={true} filter={false} filterPlaceholder="Contains"/>
                            <Column field="status" header="Status" sortable={true} filter={false} filterPlaceholder="Contains"/>
                            <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }
}