import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { WalletService } from '../../service/WalletService';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

export default class EditGroup extends Component {

	constructor(props) {
        super(props);
        this.state = {
            groupName: '',
            contributionAmount: 0,
            groupId: 0,
            alert: ''
        };
        this.walletService = new WalletService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        this.walletService.groupInfo(this.props.match.params.groupid, "GET")
        .then(response => {
            this.setState({
                groupId: response.id,
                groupName: response.groupName,
                contributionAmount: response.conAmt
            });
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();
        let groupJson = {
            groupId: this.state.groupId,
            groupName: this.state.groupName,
            contributionAmount: this.state.contributionAmount
        };
        this.walletService.group(groupJson, 'PUT')
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/wallet/list/groups");
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/wallet/edit/group",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        { this.state.failed === true &&
                            <div className="p-messages p-component p-messages-warn" style={{margin: '0 0 1em 0', display: 'block'}}>
                                    <div className="p-messages-wrapper rounded">
                                        <ul>
                                            <li>
                                                <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                                <span className="p-messages-detail">{this.state.alert}</span>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        }

                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#90009E", color: "#FFFFFF"}}>
                                <h2 style={{textAlign: "center", padding: "1%"}}>Edit Group</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-md-offset-1 p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Group Name:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="groupName" placeholder="eg: Form One" value={this.state.groupName} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-5">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="input">Contribution Amount:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <InputText type="text" name="contributionAmount" placeholder="eg: 1,000,000" value={this.state.contributionAmount} onChange={this.handleInputChange} required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label="Save" icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}