import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { HomeService } from '../service/HomeService';
import { ClientService } from '../service/ClientService';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import "../adalipa/form.css";

const stringValidation = (fieldName, fieldValue) => {
    if (fieldValue.trim() === '') {
      return `${fieldName} is required`;
    }
    if (/[^a-zA-Z -]/.test(fieldValue)) {
      return 'Invalid string';
    }
    if (fieldValue.trim().length < 3) {
      return `${fieldName} needs to be at least three characters`;
    }
    return null;
  };
  
  const numberValidation = (fieldName, fieldValue) => {
    if (fieldValue.trim() === 0) {
      return `${fieldName} is required`;
    }
    if (/[^0-9-]/.test(fieldValue)) {
      return 'Invalid number';
    }
    return null;
  };
  
  const emailValidation = email => {
    if (
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      )
    ) {
      return null;
    }
    if (email.trim() === '') {
      return 'Email is required';
    }
    return 'Please enter a valid email';
  };

export default class AddClient extends Component {

	constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            confirm: '',
            full_name: '',
            phone_number: '',
            designation: '',
            office_email: '',
            office_phone: '',
            institute_name: '',
            region_id: 2,
            business_id: 0,
            category_id: 3,
            account_number: '',
            reg_no: '',
            address: '',
			alert: '',
            regions: [],
            categories: []
        };
        this.homeService = new HomeService();
        this.clientService = new ClientService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount() {
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
        this.homeService.getRegions('none', 0, 100, 'id', 'asc')
        .then(response => {
            const data = response.content;
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.regionName
            }))
            this.setState({regions: options});
        });
        this.homeService.getCategories('none', 0, 100, 'id', 'asc')
        .then(response => {
            const data = response.content;
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.categoryName
            }))
            this.setState({categories: options});
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();
        const clientJson = {
            username: this.state.username,
            password: this.state.password,
            full_name: this.state.full_name,
            phone_number: this.state.phone_number,
            designation: this.state.designation,
            office_email: this.state.username,
            office_phone: this.state.phone_number,
            institute_name: this.state.institute_name,
            region_id: this.state.region_id,
            category_id: this.state.category_id,
            account_number: this.state.account_number,
            reg_no: this.state.reg_no,
            address: this.state.address
        };
        this.clientService.addClient(clientJson)
        .then(response => {
			this.setState({failed: false});
			this.setState({alert: response.message});
            this.props.history.push("/client/list-clients");
        }).catch(error => {
			this.setState({failed: true});
			this.setState({alert: error.message});
        });
	}

	render() {
		if(this.props.authenticated) {
			return<Redirect
			to={{
				pathname: "/client/list-clients",
				state: { from: this.props.location }
			}}/>;
		}
		return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-messages p-component p-messages-warn" style={{margin: '0 0 1em 0', display: 'block'}}>
                            { this.state.failed === true &&
                                <div className="p-messages-wrapper rounded">
                                    <ul>
                                        <li>
                                            <span className="p-messages-icon pi pi-fw pi-2x pi-check"></span>
                                            <span className="p-messages-detail">{this.state.alert}</span>
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>

                        <div className="card card-w-title">
                            <div className="p-card-title" style={{backgroundColor: "#90009E", color: "#FFFFFF"}}>
                                <h1 style={{textAlign: "center"}}>Client Registration</h1>
                            </div>
                            <div className="divider-container"><div className="divider-border" />
                            <span className="divider-content"><h3 style={{textAlign: "center"}}>User Details</h3></span>
                            <div className="divider-border" /></div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="input">Full Name:</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText type="text" name="full_name" placeholder="eg: John Doe" value={this.state.full_name} onChange={this.handleInputChange} required/>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="input">Designation:</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText type="text" name="designation" placeholder="eg: Manager" value={this.state.designation} onChange={this.handleInputChange} required/>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="input">Username:</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText type="email" name="username" placeholder="eg: user@adalipa.co.tz" value={this.state.username} onChange={this.handleInputChange} required/>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="input">Phone Number:</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText type="text" name="phone_number" placeholder="eg: +25570000000" value={this.state.phone_number} onChange={this.handleInputChange} required/>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="password">Password:</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText type="password" name="password" placeholder="Password" value={this.state.password} onChange={this.handleInputChange} required/>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="password">Confirm:</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText type="password" name="confirm" placeholder="Password" value={this.state.confirm} onChange={this.handleInputChange} required/>
                                    </div>
                                </div>
                                <div className="divider-container"><div className="divider-border" />
                                <span className="divider-content"><h3 style={{textAlign: "center"}}>Institution Details</h3></span>
                                <div className="divider-border" /></div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="input">Institution Name:</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText type="text" name="institute_name" placeholder="eg: DataVision International" value={this.state.institute_name} onChange={this.handleInputChange} required/>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="input">Bank Account Number:</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText type="text" name="account_number" placeholder="eg: 1111111111111" value={this.state.account_number} onChange={this.handleInputChange} required/>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="dropdown">Institution Category:</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown options={this.state.categories} value={this.state.category_id} onChange={event => this.setState({category_id: event.value})} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="dropdown">Region: </label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown options={this.state.regions} value={this.state.region_id} onChange={event => this.setState({region_id: event.value})} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="input">Registration Number: </label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText type="text" name="reg_no" placeholder="eg: S0108" value={this.state.reg_no} onChange={this.handleInputChange} required/>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="input">Address: </label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText type="text" name="address" placeholder="eg: Ubungo, Dar es Salaam" value={this.state.address} onChange={this.handleInputChange} required/>
                                    </div>
                                </div>
                                <div className="p-grid" style={{marginTop: "1%"}}>
                                    <div className="p-col-12 p-md-offset-4 p-md-4">
                                        <Button type="submit" label="Register Client" icon="pi pi-plus" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}