import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { WalletService } from '../service/WalletService';
import { Paginator } from 'primereact/paginator';
import { AdalipaUtil } from '../Utilities/AdalipaUtil';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";

class CollectionFee extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue:[],
            excelData:[],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            mobileNumber: '',
            receipt: '',
            state: '',
            status: '',
            Status: null,
            sortOrder: -1,
            sortField: 'id',
            loading: false
        };

        this.walletService = new WalletService();
        this.adalipaUtil = new AdalipaUtil();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.export = this.export.bind(this);

        this.cols = [
            { field: 'mobileNumber', header: 'Mobile Number' },
            { field: 'receipt', header: 'Receipt Number' },
            { field: 'state', header: 'Fee State' },
            { field: 'status', header: 'Status' },
            { field: 'amount', header: 'Fee Amount' },
            { field: 'updatedTime', header: 'Updated Time' }
        ];
        this.excelCols = {header: ['mobileNumber', 'receipt', 'state', 'status', 'amount', 'updatedTime']};
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }

    componentDidMount() {
        this.setState({loading: true});
        this.walletService.getCollectionFees(this.state.mobileNumber, this.state.receipt, this.state.state, this.state.status, 0, this.state.pageSize, 'id', 'asc')
        .then(data => {
            this.setState({loading: false});
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
            const excelOptions = data.content.map(d => ({
                mobileNumber: d.mobileNumber, receipt: d.receipt, state: d.state, status: d.status, amount: d.amount, updatedTime: d.updatedTime
            }));
            this.setState({excelData: excelOptions});
        });
    }

    onPageChange(event) {
        this.setState({loading: true});
        this.walletService.getCollectionFees(this.state.mobileNumber, this.state.receipt, this.state.state, this.state.status, event.page, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
        .then(data => {
            this.setState({loading: false});
            this.setState({dataTableValue: data.content});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
            this.setState({first: event.first, pageSize: event.rows});
            const excelOptions = data.content.map(d => ({
                mobileNumber: d.mobileNumber, receipt: d.receipt, state: d.state, status: d.status, amount: d.amount, updatedTime: d.updatedTime
            }));
            this.setState({excelData: excelOptions});
        });
    }

    onSortChange(event) {
        this.setState({loading: true});
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.walletService.getCollectionFees(this.state.mobileNumber, this.state.receipt, this.state.state, this.state.status, 0, this.state.pageSize, this.state.sortField, this.state.sortOrder === 1 ? 'asc' : 'desc')
            .then(data => {
                this.setState({loading: false});
                this.setState({dataTableValue: data.content});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
                const excelOptions = data.content.map(d => ({
                    mobileNumber: d.mobileNumber, receipt: d.receipt, state: d.state, status: d.status, amount: d.amount, updatedTime: d.updatedTime
                }));
                this.setState({excelData: excelOptions});
            });
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.setState({loading: true});
            this.walletService.getCollectionFees(this.state.mobileNumber, this.state.receipt, this.state.state, this.state.status, 0, this.state.pageSize, 'id', 'asc')
            .then(data => {
                this.setState({loading: false});
                this.setState({dataTableValue: data.content});
                this.setState({
                    pageIndex: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    numberOfElements: data.numberOfElements,
                    totalElements: data.totalElements,
                    first: (data.pageable.pageNumber * data.totalPages) + 1
                });
                const excelOptions = data.content.map(d => ({
                    mobileNumber: d.mobileNumber, receipt: d.receipt, state: d.state, status: d.status, amount: d.amount, updatedTime: d.updatedTime
                }));
                this.setState({excelData: excelOptions});
            });
        });     
    }

    booleanTemplate(rowData, column) {
        return rowData['enabled'] === true ? 'Yes' : 'No';
    }

    dateTemplate(rowData, column) {
        return new Intl.DateTimeFormat('sw', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.parse(rowData['updatedTime']));
    }

    export() {
        this.dt.exportCSV();
    }

    render() {
        let header = <div style={{textAlign:'left'}}>
            <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
            <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.tableExcel("CollectionFees", this.excelCols, this.state.dataTableValue)} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
            <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.printTable("Collection Fees", this.exportColumns, this.state.dataTableValue)} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
        </div>
        let mobileNumberFilter = <InputText type="text" name="mobileNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.mobileNumber} onChange={this.handleInputChange}/>
        let receiptFilter = <InputText type="text" name="receipt" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.receipt} onChange={this.handleInputChange}/>
        let stateFilter = <InputText type="text" name="state" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.state} onChange={this.handleInputChange}/>
        let statusFilter = <InputText type="text" name="status" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.status} onChange={this.handleInputChange}/>

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>{this.props.t('Collection Fees')}</h2></div>
                            <div className="p-col-8 p-md-8">
                                <Link to="/wallet/add/collection" style={{float: "right"}}><Button label={this.props.t("Add Collection")} icon="pi pi-plus"/></Link>
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header={header} paginator={false} 
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="mobileNumber" header={this.props.t("Mobile Number")} sortable={true} filter={true} filterElement={mobileNumberFilter} filterPlaceholder="Contains"/>
                            <Column field="receipt" header={this.props.t("Receipt Number")} sortable={true} filter={true} filterElement={receiptFilter} filterPlaceholder="Contains"/>
                            <Column field="state" header={this.props.t("Fee State")} sortable={true} filter={true} filterElement={stateFilter} filterPlaceholder="Contains"/>
                            <Column field="status" header={this.props.t("Status")} sortable={true} filter={true} filterElement={statusFilter} filterPlaceholder="Contains"/>
                            <Column field="amount" header={this.props.t("Fee Amount")} sortable={true} filter={false}/>
                            <Column field="updatedTime" body={this.dateTemplate} header={this.props.t("Updated Time")} sortable={true} filter={false}/>
                        </DataTable>
                        <Paginator currentPageReportTemplate={"Showing " + ((this.state.pageIndex * this.state.pageSize) + 1) + " to " + ((this.state.pageIndex * this.state.pageSize) + this.state.pageSize) + " of " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30]} onPageChange={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
                        </Paginator>
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#90009E"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(CollectionFee)