import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { UserService } from '../../service/UserService';
import { LgaService } from '../../service/LgaService';
import { AdalipaUtil } from '../../Utilities/AdalipaUtil';
import { Dropdown } from 'primereact/dropdown';
import BeatLoader from "react-spinners/BeatLoader";
import { withTranslation } from "react-i18next";

class PayTransfers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue:[],
            users: [],
            types: [{id: 'PAYMENT', name: 'Payment'}, {id: 'COLLECTION', name: 'Collection'}],
            first: 0,
            pageIndex: 0,
            pageSize: 10,
            numberOfElements: 0,
            totalPages: 0,
            totalElements: 0,
            addedById: 0,
            type: '',
            wrongNumber: '',
            correctNumber: '',
            receiptNumber: '',
            details: '',
            search: '',
            reqTimeFrom: '',
            reqTimeTo: '',
            sortOrder: -1,
            sortField: 'id',
            loading: false,
            displayDateFilter: false
        };

        this.userService = new UserService();
        this.lgaService = new LgaService();
        this.adalipaUtil = new AdalipaUtil();
        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.export = this.export.bind(this);
        this.dialogFooter = this.dialogFooter.bind(this);

        this.cols = [
            { field: 'generatedAt', header: this.props.t('Transfered Time') },
            { field: 'wrongNumber', header: this.props.t('Wrong Reference') },
            { field: 'correctNumber', header: this.props.t('Correct Reference') },
            { field: 'receiptNumber', header: this.props.t('Receipt Number') },
            { field: 'addedBy', header: this.props.t('Added By') },
        ];
        this.excelCols = {header: ['generatedAt', 'wrongNumber', 'correctNumber', 'receiptNumber', 'addedBy', 'details']};
        this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    }

    getDataList = () => {
        let {addedById, type, wrongNumber, correctNumber, receiptNumber, details, search, reqTimeFrom, reqTimeTo, pageIndex, pageSize, sortField, sortOrder} = this.state;
        const rqFr = this.adalipaUtil.cleanDateFormat(reqTimeFrom);
        const rqTo = this.adalipaUtil.cleanDateFormat(reqTimeTo);
        this.setState({loading: true});
        return this.lgaService.getTransferredBillsPayments({addedById: addedById, type: type, wrongNumber: wrongNumber, correctNumber: correctNumber, receiptNumber: receiptNumber, details: details, searchKey: search, reqTimeFrom: rqFr, reqTimeTo: rqTo, page: pageIndex, limit: pageSize, sort: sortField, order: sortOrder === 1 ? 'asc' : 'desc'})
        .then(data => {
            this.setState({loading: false});
            const dataContent = data.content.map(d => ({
                ...d,
                generatedAt: this.adalipaUtil.normalTimeStampFormat(d.generatedAt),
                addedBy: d.addedBy.fullName
            }))
            this.setState({dataTableValue: dataContent});
            this.setState({
                pageIndex: data.pageable.pageNumber,
                totalPages: data.totalPages,
                numberOfElements: data.numberOfElements,
                totalElements: data.totalElements,
                first: (data.pageable.pageNumber * data.totalPages) + 1
            });
        });
    }

    componentDidMount() {
        this.getDataList();
        const jsonParams = { page: 0, limit: 100, sort: "id", order: 'asc' }
        this.userService.getStaffs(jsonParams)
        .then(data => {
            this.setState({users: data.content});
        });
    }

    onPageChange(event) {
        this.setState({pageIndex: event.page, pageSize: event.rows}, () => {
            this.getDataList().then(res => {
                this.setState({first: event.first, pageSize: event.rows});
            })
        });
    }

    onSortChange(event) {
        this.setState({sortOrder: event.sortOrder, sortField: event.sortField}, () => {
            this.getDataList();
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });     
    }

    handleDropdownChange(event) {
        const target = event;
        const inputName = target.target.name;        
        const inputValue = target.value;
        this.setState({
            [inputName] : inputValue
        }, () => {
            this.getDataList();
        });
    }

    onDateFilter() {
        this.setState({displayDateFilter: false});
        this.getDataList();
    }

    clearDateFilter = () => {
        this.setState({
            reqTimeFrom: "",
            reqTimeTo: ""
        }, () => {
            this.getDataList()
        });
    }

    dialogFooter = () => <div className="p-grid">
        <div className="p-col-6 p-text-left">
            <Button type="button" label="Dismiss" onClick={() => this.setState({displayDateFilter: false})} icon="pi pi-times-circle" className="p-button-warning" />
        </div>
    </div>;

    dateTemplate(rowData, column) {
        return new Intl.DateTimeFormat('sw', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.parse(rowData['generatedAt']));
    }

    actionTemplate(rowData, column) {
        return <div style={{whiteSpace: "nowrap"}}>
            <Link to={"/wallet/lga/pay/transfer/" + rowData["id"] + "/0"}><Button type="button" icon="pi pi-pencil" className="p-button-warning" style={{marginRight: '.2em'}} title={this.props.t("Edit Cell")}/></Link>
        </div>;
    }

    export() {
        this.dt.exportCSV();
    }

    render() {

        let header = (
        <div style={{textAlign:'left'}}>
            <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button>
            <Button type="button" icon="pi pi-file-excel" iconPos="left" label="Excel" onClick={() => this.adalipaUtil.tableExcel(this.props.t("List of Transfered Payments"), this.excelCols, this.state.dataTableValue)} className="p-button-success p-button-sm" data-pr-tooltip="XLS"></Button>
            <Button type="button" icon="pi pi-print" iconPos="left" label="Pdf" onClick={() => this.adalipaUtil.printTable(this.props.t("List of Transfered Payments"), this.exportColumns, this.state.dataTableValue)} className="p-button-warning p-button-sm" data-pr-tooltip="PDF" />
        </div>
        );

        let dateFilter = <div style={{padding: "0px", margin: "0px"}}>
            <Link to="#" onClick={() => this.setState({displayDateFilter: true})}><i className="pi pi-calendar" style={{marginLeft: "0px", paddingLeft: "0px", verticalAlign: "bottom"}}></i><small>{this.props.t("Filter")}</small></Link>&nbsp;&nbsp;
            <Link to="#" onClick={() => this.clearDateFilter()}><i className="pi pi-times-circle" style={{marginLeft: "0px", paddingLeft: "0px", verticalAlign: "bottom"}}></i><small>{this.props.t("Clear")}</small></Link>
        </div>
        let wrongNumberFilter = <InputText type="text" name="wrongNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.wrongNumber} onChange={this.handleInputChange}/>;
        let correctNumberFilter = <InputText type="text" name="correctNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.correctNumber} onChange={this.handleInputChange}/>;
        let receiptNumberFilter = <InputText type="text" name="receiptNumber" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.receiptNumber} onChange={this.handleInputChange}/>;
        let addedByFilter = <Dropdown name="addedById" style={{width: '100%'}} placeholder={this.props.t("Choose User")} options={this.state.users} value={this.state.addedById} onChange={this.handleDropdownChange} optionLabel="fullName" optionValue="id" filter filterBy="name" autoWidth={false} />;

        // let detailsFilter = <InputText type="text" name="details" style={{width: '100%'}} placeholder={this.props.t("Contains")} value={this.state.details} onChange={this.handleInputChange}/>;
        // let typeFilter = <Dropdown name="type" style={{width: '100%'}} placeholder={this.props.t("Choose Type")} options={this.state.types} value={this.state.type} onChange={this.handleDropdownChange} optionLabel="name" optionValue="id" filter filterBy="name" autoWidth={false} />;
        // let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-4 p-md-4"><h2>{this.props.t('List of Transfered Payments')}</h2></div>
                            <div className="p-col-8 p-md-8">
                                <Link to="/wallet/lga/pay/transfer/0/0" style={{float: "right"}}><Button label={this.props.t("Transfer Payment")} icon="pi pi-plus"/></Link>
                            </div>
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} selectionMode="single" header={header} paginator={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder} onSort={this.onSortChange}
                            responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})} 
                            currentPageReportTemplate={this.props.t("Data") + " " + ((this.state.pageIndex * this.state.pageSize) + 1) + " " + this.props.t("to") + " " + ((this.state.pageIndex * this.state.pageSize) + this.state.numberOfElements) + " " + this.props.t("of") + " " + this.state.totalElements} 
                            first={this.state.first} rows={this.state.pageSize} rowsPerPageOptions={[10,20,30,50,100,[this.state.totalElements]]} onPage={this.onPageChange} totalPages={this.state.totalPages} totalRecords={this.state.totalElements}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" lazy={true}>
                            <Column field="generatedAt" header={this.props.t("Transfered Time")} sortable={true} filter={true} filterElement={dateFilter}/>
                            <Column field="wrongNumber" header={this.props.t("Wrong Reference")} sortable={true} filter={true} filterElement={wrongNumberFilter}/>
                            <Column field="correctNumber" header={this.props.t("Correct Reference")} sortable={true} filter={true} filterElement={correctNumberFilter}/>
                            <Column field="receiptNumber" header={this.props.t("Receipt Number")} sortable={true} filter={true} filterElement={receiptNumberFilter}/>
                            {/* <Column field="type" header={this.props.t("Transfer Type")} sortable={true} filter={true} filterElement={typeFilter}/> */}
                            <Column field="addedBy" header={this.props.t("Added By")} sortable={true} filter={true} filterElement={addedByFilter}/>
                            {/* <Column field="details" header={this.props.t("Details")} sortable={true} filter={true} filterElement={detailsFilter}/> */}
                            {/* <Column header={actionHeader} body={this.actionTemplate} style={{textAlign:'center', width: '8em'}}/> */}
                        </DataTable>
                        {this.state.loading && 
                            <div className="p-grid p-fluid" style={{textAlign:'center', padding: "2em"}}>
                                <div className="p-col-12" style={{textAlign:'center'}}>
                                    <BeatLoader color={"#90009E"} loading={this.state.loading} css={""} size={10} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <Dialog visible={this.state.displayDateFilter} className="p-col-12 p-md-6 p-lg-6 p-md-offset-1 p-lg-offset-2" modal onHide={() => this.setState({displayDateFilter: false})}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12 p-text-center">
                        <form onSubmit={this.handleSubmit}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-2 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">{this.props.t("Start From")}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Calendar name="reqTimeFrom" placeholder={this.props.t("Choose Date")} value={this.state.reqTimeFrom} onChange={(e) => this.setState({reqTimeFrom: e.value})} dateFormat="yy-mm-d" showIcon showButtonBar showOnFocus icon="pi pi-calendar" appendTo={document.body}/>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-col-12 p-md-12">
                                            <label htmlFor="calendar">{this.props.t("End To")}:</label>
                                        </div>
                                        <div className="p-col-12 p-md-12">
                                            <Calendar name="reqTimeTo" placeholder={this.props.t("Choose Date")} value={this.state.reqTimeTo} onChange={(e) => this.setState({reqTimeTo: e.value})} dateFormat="yy-mm-d" showIcon showButtonBar showOnFocus icon="pi pi-calendar" appendTo={document.body}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-offset-5 p-md-2">
                                        <Button type="button" label="Filter" icon="pi pi-filter" onClick={() => this.onDateFilter()} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}
export default withTranslation()(PayTransfers)
